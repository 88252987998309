import React from "react";

import { formatWalletAddress } from "@services/eth/utils/formatWalletAddress";
import { copyToClipboard } from "@utils/copyToClipboard";
import { useWallet } from "@contexts/WalletProvider";

import Tooltip from "@components/toolkit/Tooltip/Tooltip";

import { ReactComponent as CorrectNetworkIcon } from "@images/icons/icon-correct-network.svg";
import { ReactComponent as IncorrectNetworkIcon } from "@images/icons/icon-incorrect-network.svg";

import "./WalletAddress.scss";

interface WalletAddressProps {
  address: string;
}

const WalletAddress = ({ address }: WalletAddressProps) => {
  const { isOnCorrectNetwork } = useWallet();

  const formattedAddress = formatWalletAddress(address);
  const handleCopyToClipboard = () => copyToClipboard(address);

  const walletAddressIconPath = isOnCorrectNetwork ? (
    <CorrectNetworkIcon width={21} height={20} />
  ) : (
    <IncorrectNetworkIcon width={21} height={20} />
  );

  return (
    <Tooltip tip={address}>
      <div className="wallet-address" onClick={handleCopyToClipboard}>
        <p className="medium">{formattedAddress}</p>
        {walletAddressIconPath}
      </div>
    </Tooltip>
  );
};

export default WalletAddress;
