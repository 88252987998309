import Group from "@components/arrangement/Group/Group";
import "./StakingModalTimeline.scss";
import { formatDateTime } from "@utils/formatDateTime";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { StakingStages } from "@src/types/Staking.types";

interface IStakingModalTimeline {
  stages?: StakingStages;
}

const StakingModalTimeline = ({ stages }: IStakingModalTimeline) => {
  const { t } = useTranslation("modals");
  const parsedStages = Object.entries(stages ?? {});
  return (
    <>
      <div className="timeline">
        {parsedStages.map(([key, date], index) => {
          const filled = Date.now() > date;
          return (
            <div key={date}>
              <Group>
                <div className="ball">
                  <div className={filled ? "" : "ball-not-filled"} />
                </div>

                <Group justifyContent="space-between" className="ball-text">
                  <p className="p1">{t(`staking.${key}` as any)}</p>
                  <p className="p1 bold">{formatDateTime(date, undefined, "YYYY-MM-DD HH:mm")}</p>
                </Group>
              </Group>

              {index + 1 < parsedStages.length && (
                <Group>
                  <div className={`line ${filled ? "line-filled" : ""}`} />

                  {(key === "subscribeStageTo" || key === "earnStageTo") && (
                    <Group fullWidth justifyContent="space-between" className="line-text">
                      <p className="p1">{t(`staking.${key}Period` as any)}</p>

                      <p className="p1 bold">
                        {Math.ceil(dayjs(parsedStages[index + 1][1]).diff(date, "d", true))} {t("staking.days")}
                      </p>
                    </Group>
                  )}
                </Group>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default StakingModalTimeline;
