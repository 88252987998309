import { Outlet, RouteObject } from "react-router-dom";

import { PATHS } from "../paths";

import { MetaMaskProvider } from "metamask-react";
import { WalletProvider } from "@contexts/WalletProvider";
import { StakingListProvider } from "@contexts/StakingListContext/StakingList.context";
import { UserBalancesProvider } from "@contexts/UserBalances.context";
import { ModalProvider } from "@contexts/Modal.context";

import MobileDeviceGuard from "@guards/MobileDeviceGuard/MobileDeviceGuard";
import OfflineNetworkGuard from "@guards/OfflineNetworkGuard/OfflineNetworkGuard";
import ArchivedStaking from "@guards/ArchivedStaking/ArchivedStaking";
import SupportedBrowserGuard from "@guards/SupportedBrowserGuard/SupportedBrowserGuard";
import SwitchNetworkGuard from "@guards/SwitchNetworkGuard/SwitchNetworkGuard";
import MetamaskGuard from "@guards/MetamaskGuard/MetamaskGuard";

import SkeyStakings from "@app/SkeyStakings/SkeyStakings";
import StakingDashboard from "@app/StakingDashboard/StakingDashboard";
import Debug from "@app/Debug/Debug";
import NotFound from "@app/NotFound/NotFound";

export const mainRoutes: RouteObject = {
  element: (
    <>
      <Outlet />
    </>
  ),
  children: [
    {
      path: PATHS.main.path,
      element: SkeyStakings
    },
    {
      path: PATHS.stakingDashboard.path,
      element: StakingDashboard
    }
  ]
};

export const otherRoutes: RouteObject = {
  element: <Outlet />,
  children: [
    {
      path: PATHS.debug.path,
      element: Debug
    },
    {
      path: PATHS.notFound.path,
      element: NotFound
    }
  ]
};

export const appRoutes: RouteObject = {
  element: (
    <MetaMaskProvider>
      <WalletProvider>
        <UserBalancesProvider>
          <ModalProvider>
            <MobileDeviceGuard>
              <OfflineNetworkGuard>
                <ArchivedStaking>
                  <SupportedBrowserGuard>
                    <SwitchNetworkGuard>
                      <StakingListProvider>
                        <MetamaskGuard>
                          <Outlet />
                        </MetamaskGuard>
                      </StakingListProvider>
                    </SwitchNetworkGuard>
                  </SupportedBrowserGuard>
                </ArchivedStaking>
              </OfflineNetworkGuard>
            </MobileDeviceGuard>
          </ModalProvider>
        </UserBalancesProvider>
      </WalletProvider>
    </MetaMaskProvider>
  ),
  children: [mainRoutes, otherRoutes]
};
