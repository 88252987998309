import Group from "@components/arrangement/Group/Group";
import Stack from "@components/arrangement/Stack/Stack";
import "./StakingModalWithdraw.scss";
import { AppConfig } from "@config/config";

interface IWithdrawInfo {
  label: string;
  userBalance?: string | number;
  className?: string;
}

const WithdrawInfo = ({ label, className, userBalance }: IWithdrawInfo) => {
  return (
    <Group className={`withdraw-info ${className}`} gap={20}>
      <img src="/images/icons/icon-withdraw.svg" />
      <Stack fullWidth>
        <p className="p1">{label}</p>
        <p className="p1 bold staked-amount">
          {userBalance} {AppConfig.EthTokenSymbol}
        </p>
      </Stack>
    </Group>
  );
};

export default WithdrawInfo;
