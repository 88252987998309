import React from "react";
import { useTranslation } from "react-i18next";

import { AppConfig } from "@config/config";
import { faqQuestions } from "@constants/faqQuestions";
import { skeyTokenConfig } from "@config/skeyTokenConfig";
import addTokenToMetamask from "@services/eth/utils/addTokenToMetamask";

import Accordion from "@components/common/Accordion/Accordion";
import Stack from "@components/arrangement/Stack/Stack";
import Button from "@components/toolkit/Button/Button";

import "./Faq.scss";

const Faq = () => {
  const { t } = useTranslation("faq");

  const faqQuestionsWithoutButtons = faqQuestions.slice(1);

  const handleAddTokenToMetamask = () => addTokenToMetamask(skeyTokenConfig);

  return (
    <div className="faq">
      <h2 className="title">FAQ</h2>

      <Accordion>
        <Accordion.Tab>
          <Accordion.Summary>
            <p className="p2 bold">{t(faqQuestions[0].question as any)}</p>
          </Accordion.Summary>
          <Accordion.Details>
            <Stack rowGap={10} className="faq-answer-content">
              <p className="p1 text-justify">
                {t(faqQuestions[0].answer as any, { token: AppConfig.EthTokenAddress })}
              </p>

              <Button variant="trietary" className="add-token-btn" onClick={handleAddTokenToMetamask}>
                {t(faqQuestions[0].buttonLabel as any)}
              </Button>
            </Stack>
          </Accordion.Details>
        </Accordion.Tab>

        {faqQuestionsWithoutButtons?.map(({ question, answer }, index) => {
          return (
            <Accordion.Tab key={index}>
              <Accordion.Summary>
                <p className="p2 bold">{t(question as any)}</p>
              </Accordion.Summary>
              <Accordion.Details>
                <Stack className="faq-answer-content">
                  <p className="p1 text-justify">{t(answer as any)}</p>
                </Stack>
              </Accordion.Details>
            </Accordion.Tab>
          );
        })}
      </Accordion>
    </div>
  );
};

export default Faq;
