import React from "react";
import { useTranslation } from "react-i18next";

import { AppConfig } from "@config/config";
import { useStakingList } from "@contexts/StakingListContext/StakingList.context";

import MainLayout from "@components/layouts/MainLayout/MainLayout";
import Link from "@components/toolkit/Link/Link";
import Group from "@components/arrangement/Group/Group";
import Stack from "@components/arrangement/Stack/Stack";
import StakingDashboardCard from "./components/StakingDashboardCard/StakingDashboardCard";
import Spinner from "@components/toolkit/Spinner/Spinner";
import Button from "@components/toolkit/Button/Button";

import { ReactComponent as RefetchIcon } from "@images/icons/icon-refetch.svg";

import "./StakingDashboard.scss";

const StakingDashboard = () => {
  const { stakingList, fetchStatus, fetchStakingList } = useStakingList();
  const { t } = useTranslation(["common"]);

  const aggregatorUrl = AppConfig.EthExplorerUrl + "/address/" + AppConfig.EthAggregatorAddress;

  const isLoading = fetchStatus === "loading";
  const isError = fetchStatus === "error";

  const handleRefetchStakingList = () => fetchStakingList(true);

  return (
    <div className="staking-dashboard">
      <div className="staking-dashboard-header">
        <h2>Staking Dashboard</h2>

        <Group gap={4}>
          <p className="p3 bold">Aggregator:</p>

          <Link href={aggregatorUrl} target="_blank" className="p3">
            {AppConfig.EthAggregatorAddress}
          </Link>
        </Group>
      </div>

      <div className="staking-dashboard-main">
        {isLoading && (
          <Stack gap={10} alignItems="center" fullWidth className="mt-5">
            <Spinner variant="blue" sizePx={42} />
            <p className="p1 loading-dots">{t("common:loading")}</p>
          </Stack>
        )}

        {stakingList?.map((staking) => {
          return <StakingDashboardCard key={staking.address} staking={staking} />;
        })}

        {isError && !isLoading && (
          <Stack rowGap={16} alignItems="center" justifyContent="center" fullSize>
            <RefetchIcon width={64} height={64} />
            <Stack alignItems="center">
              <h2 className="danger">Something went wrong!</h2>
              <p className="p1">Please try again fetch stakings data</p>
            </Stack>

            <Button onClick={handleRefetchStakingList} uppercase className="mt-4">
              {t("common:tryAgain")}
            </Button>
          </Stack>
        )}
      </div>
    </div>
  );
};

export default (
  <MainLayout>
    <StakingDashboard />
  </MainLayout>
);
