import React, { useEffect } from "react";

import { IChildren } from "@src/types/IChildren.types";

import useBoolean from "@hooks/useBoolean";
import useNetworkStatus from "@hooks/useNetworkStatus";

import OfflineNetwork from "./OfflineNetwork/OfflineNetwork";

const OfflineNetworkGuard = ({ children }: IChildren) => {
  const [isModalOpen, openModal, closeModal] = useBoolean();
  const isOnline = useNetworkStatus();

  useEffect(() => {
    if (!isOnline) openModal();
    else closeModal();
  }, [isOnline]);

  return (
    <>
      {children}
      {isModalOpen && <OfflineNetwork />}
    </>
  );
};

export default OfflineNetworkGuard;
