import { useModalContext } from "@contexts/Modal.context";
import { useUnstakeTx, useClaimTx } from "@services/eth/EthHooks";
import { ClaimLoading, ClaimSuccess, ClaimError } from "./components/Claim/ClaimStatusModal";
import { WithdrawLoading, WithdrawSuccess, WithdrawError } from "./components/Withdraw/WithdrawStatusModal";

export const useStakingModalFlow = (stakingIndex: number) => {
  const { popModal, replaceModal } = useModalContext();
  const { sendUnstakeTx } = useUnstakeTx(stakingIndex);
  const { claim } = useClaimTx(stakingIndex);

  const showWithdrawModal = async (userBalance: number | undefined, onClose?: () => void, repeat?: boolean) => {
    try {
      replaceModal(<WithdrawLoading withdrawValue={userBalance!} />);
      await sendUnstakeTx();
      replaceModal(<WithdrawSuccess withdrawValue={userBalance!} onClose={onClose ?? popModal} />);
    } catch (e) {
      replaceModal(
        <WithdrawError
          withdrawValue={userBalance!}
          tryAgain={repeat ? () => showWithdrawModal(userBalance, onClose, repeat) : popModal}
          onClose={onClose ?? popModal}
        />
      );
    }
  };

  const showClaimModal = async (
    userBalance: number | undefined,
    earningPercent: number,
    onClose?: () => void,
    repeat?: boolean
  ) => {
    const claimValue = userBalance! + (userBalance! * earningPercent) / 100;
    try {
      replaceModal(<ClaimLoading claimValue={claimValue} />);
      await claim();
      replaceModal(<ClaimSuccess claimValue={claimValue} onClose={onClose ?? popModal} />);
    } catch (e) {
      replaceModal(
        <ClaimError
          claimValue={claimValue}
          tryAgain={repeat ? () => showClaimModal(userBalance, earningPercent, onClose, repeat) : popModal}
          onClose={onClose ?? popModal}
        />
      );
    }
  };

  return { showWithdrawModal, showClaimModal };
};
