import { StakingDataStructOutput } from "staking-contract/lib/contracts/Staking";

import { getCurrentDateMiliseconds } from "@utils/getCurrentDate";
import { StakingStatus } from "../../../types/Staking.types";

export const getCurrentStakingStatus = (data?: StakingDataStructOutput): StakingStatus => {
  if (!data) return "unknown";

  // Smart contract has timestamps in seconds format
  const now = getCurrentDateMiliseconds() / 1000;

  if (now < data.subscribeStageFrom) return "pending";
  if (now >= data.subscribeStageFrom && now < data.subscribeStageTo) return "subscribe";
  if (now >= data.subscribeStageTo && now < data.earnStageTo) return "earn";
  if (now >= data.earnStageTo && now < data.claimStageTo) return "claim";
  if (now >= data.claimStageTo) return "finished";

  return "unknown";
};
