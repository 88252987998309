import React from "react";
import { useTranslation } from "react-i18next";

import { copyToClipboard } from "@utils/copyToClipboard";

import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/overlay/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import Button from "@components/toolkit/Button/Button";

import { ReactComponent as MobileDeviceIcon } from "@images/icons/icon-mobile-device.svg";

import "./UnavailableOnMobile.scss";

const UnavailableOnMobile = () => {
  const { t } = useTranslation("modals");
  const currentUrl = window.location.href;

  const handleCopyUrl = () => copyToClipboard(currentUrl);

  return (
    <Portal>
      <Backdrop open>
        <Modal>
          <div className="unavailable-on-mobile-modal">
            <MobileDeviceIcon className="mb-6" />

            <h3 className="bold mb-1">{t("unavailableOnMobile.title")}</h3>
            <p className="p1 mb-6">{t("unavailableOnMobile.description")}</p>

            <Button onClick={handleCopyUrl} fullWidth>
              {t("unavailableOnMobile.button")}
            </Button>
          </div>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default UnavailableOnMobile;
