import React from "react";
import classNames from "classnames";

import { ReactComponent as SpinnerWhite } from "@images/layouts/spinner-white.svg";
import { ReactComponent as SpinnerBlue } from "@images/layouts/spinner-blue.svg";

import "./Spinner.scss";

type SpinnerVariant = "blue" | "white";

interface SpinnerProps {
  variant?: "blue" | "white";
  sizePx?: number;
  className?: string;
}

const getSpinnerCircle = (variant?: SpinnerVariant, circleStyle?: React.CSSProperties) => {
  switch (variant) {
    case "white":
      return <SpinnerWhite className="spinner-circle" style={circleStyle} />;
    case "blue":
      return <SpinnerBlue className="spinner-circle" style={circleStyle} />;
    default:
      return <SpinnerWhite className="spinner-circle" style={circleStyle} />;
  }
};

const Spinner = ({ variant = "white", sizePx = 33, className }: SpinnerProps) => {
  const classes = classNames("spinner", className);
  const circleStyle = { "--circle-size": `${sizePx}px` } as React.CSSProperties;

  return <div className={classes}>{getSpinnerCircle(variant, circleStyle)}</div>;
};

export default Spinner;
