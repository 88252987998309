import React, { useEffect } from "react";

import useBlockScroll from "@hooks/useBlockScroll";
import useClickKey from "@hooks/useClickKey";

// import ClickAwayListener from "@components/common/ClickAwayListener/ClickAwayListener";
import { ReactComponent as CloseIcon } from "@images/icons/icon-close.svg";

import "./Modal.scss";

interface ModalProps {
  children: React.ReactNode;
  onClose?: () => void;
  modalClassName?: string;
  childrenClassName?: string;
}

const Modal = ({ children, onClose, modalClassName, childrenClassName }: ModalProps) => {
  const { lock, unlock } = useBlockScroll();

  const handleClose = () => {
    onClose?.();
  };

  useClickKey("Escape", handleClose);

  useEffect(() => {
    lock();

    return () => {
      unlock();
    };
  }, []);

  return (
    // <ClickAwayListener mouseEvent="mouseup" onClickAway={handleClose}>
    <div className={`modal ${modalClassName}`}>
      <div className={childrenClassName}>{children}</div>

      {onClose && (
        <div className="modal-close-box">
          <button onClick={handleClose} className="modal-close-btn">
            <CloseIcon />
          </button>
        </div>
      )}
    </div>
    // </ClickAwayListener>
  );
};

export default Modal;
