import React from "react";
import { useTranslation } from "react-i18next";

import { EarningPercent, NumericValue } from "@src/types/Staking.types";
import { numberWithSpaces } from "@utils/numberWithSpaces";

import Group from "@components/arrangement/Group/Group";
import Stack from "@components/arrangement/Stack/Stack";

import "./StakingModalDetails.scss";

interface IStakingModalDetails {
  duration: number;
  maxStakingAmount: NumericValue;
  maxUserAmount: NumericValue;
  apy: EarningPercent;
}

const StakingModalDetails = ({ duration, maxStakingAmount, maxUserAmount, apy }: IStakingModalDetails) => {
  const { t } = useTranslation(["modals"]);
  return (
    <>
      <Group className="details-group">
        <Stack>
          <p className="p1">{t("modals:staking.stakingDuration")}</p>
          <p className="p1 bold stack-text">
            {duration} {t("modals:staking.days")}
          </p>
        </Stack>

        <Stack>
          <p className="p1">{t("modals:staking.contractMaxAmount")}</p>
          <p className="p1 bold stack-text">{numberWithSpaces(maxStakingAmount.number)}</p>
        </Stack>
      </Group>

      <Group className="details-group">
        <Stack>
          <p className="p1">{t("modals:staking.maxAmount")}</p>
          <p className="p1 bold stack-text">{numberWithSpaces(maxUserAmount.number)}</p>
        </Stack>

        <Stack>
          <p className="p1">{t("modals:staking.apy")}</p>
          <p className="p1 bold stack-text">{numberWithSpaces(apy.apr)}%</p>
        </Stack>
      </Group>
    </>
  );
};

export default StakingModalDetails;
