import React, { useState } from "react";
import classNames from "classnames";
import { Placement } from "@popperjs/core";
import { usePopper } from "react-popper";

import useBoolean from "@hooks/useBoolean";

import "./Tooltip.scss";

interface TooltipProps {
  tip: string;
  placement?: Placement;
  width?: number;
  hide?: boolean;
  fullWidth?: boolean;
  displayAfterClick?: boolean;
  tipStyle?: React.CSSProperties;
  wrapperStyle?: React.CSSProperties;
  tipClassName?: string;
  wrapperClassName?: string;
  children: React.ReactNode;
}

const Tooltip = ({
  tip,
  placement = "bottom",
  width = 0,
  hide = false,
  displayAfterClick = false,
  tipStyle,
  wrapperStyle,
  tipClassName = "",
  wrapperClassName = "",
  children
}: TooltipProps) => {
  const [tooltipRef, setTooltipRef] = useState<HTMLElement | null>(null);
  const [popperRef, setPopperRef] = useState<HTMLElement | null>(null);
  const [isVisible, setVisibilityTrue, setVisibilityFalse, visibilityToggle] = useBoolean(false);

  const handleClick = () => displayAfterClick && visibilityToggle();

  const { styles, attributes } = usePopper(tooltipRef, popperRef, {
    placement: placement,
    strategy: "fixed",
    modifiers: [
      {
        name: "offset",
        enabled: true,
        options: {
          offset: [0, 12]
        }
      },
      {
        name: "flip",
        options: {
          allowedAutoPlacements: ["top", "right", "left", "bottom"],
          rootBoundary: "viewport",
          padding: 10
        }
      },
      {
        name: "preventOverflow",
        options: {
          rootBoundary: "viewport",
          padding: 10
        }
      }
    ]
  });

  const tipWidth = width > 0 ? `${width}px` : "auto";
  const tipStyleProperties = { ...styles.popper, ...tipStyle, maxWidth: tipWidth };
  const showTooltip = !hide && isVisible;

  const tooltipWrapperClass = classNames("tooltip-wrapper", wrapperClassName);
  const tipClass = classNames("tooltip-tip", tipClassName);

  return (
    <div
      ref={setTooltipRef}
      onMouseEnter={setVisibilityTrue}
      onMouseLeave={setVisibilityFalse}
      onMouseDown={setVisibilityFalse}
      onClick={handleClick}
      style={wrapperStyle}
      className={tooltipWrapperClass}
    >
      {showTooltip && (
        <div ref={setPopperRef} role="tooltip" className={tipClass} style={tipStyleProperties} {...attributes.popper}>
          {tip}
        </div>
      )}
      {children}
    </div>
  );
};

export default Tooltip;
