import React from "react";
import { useTranslation } from "react-i18next";

import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/overlay/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";

import { ReactComponent as OfflineNetworkIcon } from "@images/icons/icon-offline-network.svg";

import "./OfflineNetwork.scss";

const OfflineNetwork = () => {
  const { t } = useTranslation(["modals"]);

  return (
    <Portal>
      <Backdrop open>
        <Modal>
          <div className="offline-network-modal">
            <OfflineNetworkIcon />

            <h3 className="bold mt-6 mb-1">{t("modals:offlineNetwork.title")}</h3>
            <p className="p1">{t("modals:offlineNetwork.description")}</p>
          </div>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default OfflineNetwork;
