import { useCallback, useEffect, useState } from "react";

export type PromiseStatus = "idle" | "pending" | "resolved" | "rejected";

export const getErrorMessage = (e: any) => {
  if (!e) return null;

  if (typeof e === "string" && e.length < 50) return e;

  if (e instanceof Error && e.message && typeof e.message === "string") {
    return e.message;
  }

  return "Unexpected Error";
};

export const usePromise = <T,>(func: (...args: any[]) => Promise<T>, initCall = false) => {
  const [status, setStatus] = useState<PromiseStatus>("idle");
  const [resolved, setResolved] = useState<T | null>(null);
  const [rejected, setRejected] = useState<Error | null>(null);

  const call = useCallback(
    async (...args: any[]) => {
      reset();
      setStatus("pending");
      try {
        const res = await func(...args);

        setResolved(res);
        setStatus("resolved");

        return res;
      } catch (error: any) {
        setRejected(error);
        setStatus("rejected");
      }
    },
    [func]
  );

  const reset = useCallback(() => {
    setStatus("idle");
    setResolved(null);
    setRejected(null);
  }, []);

  useEffect(() => {
    if (!initCall) return;

    call();
  }, [initCall]);

  return { resolved, rejected, status, call, reset };
};
