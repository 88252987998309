import React from "react";
import { useTranslation } from "react-i18next";

import { ARCHIVED_STAKING_URL } from "@constants/constants";

import Button from "@components/toolkit/Button/Button";
import ConnectMetamaskBtn from "@components/common/ConnectMetamaskBtn/ConnectMetamaskBtn";

import "./Hero.scss";

const Hero = () => {
  const { t } = useTranslation(["staking", "common"]);

  const handleOpenArchivedStaking = () => window.open(ARCHIVED_STAKING_URL, "_blank");

  return (
    <div className="staking-hero">
      <img
        src="/images/layouts/staking-hero-bg.png"
        alt="Hero section background"
        width={1520}
        height={1000}
        className="staking-hero-background"
      />

      <div className="staking-hero-content">
        <div className="content-previous">
          <Button variant="secondary" onClick={handleOpenArchivedStaking}>
            {t("common:previousStakingVersion")}
          </Button>
        </div>

        <div className="content-main">
          <h1 className="title skey-gradient-text">{t("staking:hero.title")}</h1>
          <p className="p2 mt-5">{t("staking:hero.description")}</p>

          <ConnectMetamaskBtn className="mt-9" />
        </div>
      </div>
    </div>
  );
};

export default Hero;
