import { AccordionActiveState } from "@components/common/Accordion/Accordion.types";

interface FaqQuestions {
  question: string;
  answer: string;
  buttonLabel?: string;
  inititalVariant?: AccordionActiveState;
}

export const faqQuestions: FaqQuestions[] = [
  {
    question: "faq:questions.one.question",
    answer: "faq:questions.one.answer",
    buttonLabel: "faq:questions.one.button",
    inititalVariant: "default"
  },
  {
    question: "faq:questions.two.question",
    answer: "faq:questions.two.answer"
  },
  {
    question: "faq:questions.three.question",
    answer: "faq:questions.three.answer"
  },
  {
    question: "faq:questions.four.question",
    answer: "faq:questions.four.answer"
  },
  {
    question: "faq:questions.five.question",
    answer: "faq:questions.five.answer"
  },
  {
    question: "faq:questions.six.question",
    answer: "faq:questions.six.answer"
  }
];
