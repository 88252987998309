import React from "react";
import classNames from "classnames";

import { numberWithSpaces } from "@src/utils/numberWithSpaces";
import { roundValue } from "@src/utils/roundValue";

import "./StakingProgress.scss";

interface StakingProgressProps {
  currentTotalDeposit: number;
  maxTotalStake: number;
  className?: string;
}

const StakingProgress = ({ currentTotalDeposit, maxTotalStake, className }: StakingProgressProps) => {
  const percentageProgress = (currentTotalDeposit / maxTotalStake) * 100;

  const isCompleted = currentTotalDeposit >= maxTotalStake;

  const classess = classNames("staking-progress", { "staking-progress-completed": isCompleted }, className);

  return (
    <div className={classess}>
      <div className="staking-progress-bar">
        <div className="current-point " style={{ "--progress-width": `${percentageProgress}%` } as React.CSSProperties}>
          <p className="p3">{roundValue(percentageProgress)}%</p>
        </div>
        <div className="line-inactive" />
        <div className="line-active" style={{ "--progress-width": `${percentageProgress}%` } as React.CSSProperties} />
      </div>

      <div className="staking-progress-info mt-3">
        <p className="p3">0 Skey</p>

        <p className="p3">{numberWithSpaces(maxTotalStake)} Skey</p>
      </div>
    </div>
  );
};

export default StakingProgress;
