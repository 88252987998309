import React from "react";

import { useAccordionTab } from "../AccordionTab/AccordionTab";
import { AccordionSummaryProps } from "../../Accordion.types";

import AccordionPlusIcon from "../AccordionPlusIcon/AccordionPlusIcon";

import "./AccordionSummary.scss";

const AccordionSummary = ({ children }: AccordionSummaryProps) => {
  const { variant, handleClick, handleHover, handleBlur } = useAccordionTab();

  return (
    <div
      role="button"
      onClick={handleClick}
      onMouseOver={handleHover}
      onMouseLeave={handleBlur}
      className="accordion-summary"
    >
      {children}
      <AccordionPlusIcon variant={variant} />
    </div>
  );
};

export default AccordionSummary;
