import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { AppConfig } from "@config/config";
import { stakingNamesMap } from "@constants/stakingNamesMap";
import { formatDateTimeUTC } from "@utils/formatDateTime";
import { numberWithSpaces } from "@utils/numberWithSpaces";
import { calculateEarnings } from "@services/eth/utils/calculateEarnings";
import { useUserBalances } from "@contexts/UserBalances.context";
import { useStakingModalFlow } from "@components/modals/StakingModal/useStakingModalFlow";

import { ReactComponent as ClaimStakingIcon } from "@images/icons/icon-staking-claim.svg";
import { ReactComponent as RewardIcon } from "@images/icons/icon-reward.svg";

import StakingTimeline from "@components/common/StakingTimeline/StakingTimeline";
import CardInfoItem from "@components/common/CardInfoItem/CardInfoItem";
import Button from "@components/toolkit/Button/Button";

import { StakingCardProps } from "../../StakingCard";
import "./ClaimAssignedCard.scss";

const ClaimAssignedCard = ({ staking, onModalOpen }: StakingCardProps) => {
  const { index, address, duration, status, stages, earningPercent } = staking;

  const { getStakingBalanceByIndex } = useUserBalances();
  const { t } = useTranslation(["common"]);
  const { showClaimModal } = useStakingModalFlow(index);

  const stakingName = stakingNamesMap.get(address);
  const stakingDuration =
    duration.inMonths > 0 ? `${duration.inMonths} ${t("common:months")}` : `${duration.inDays} ${t("common:days")}`;

  const userBalance = getStakingBalanceByIndex(index);
  const earnings = calculateEarnings(userBalance?.max.bigint || 0n, earningPercent.bigint);
  const stakingAmount = userBalance?.max.number || 0;

  const isClaimed = userBalance && userBalance.max.bigint > userBalance.current.bigint;

  const rewardsClasses = classNames("card-rewards", {
    "card-rewards--notClaimed": !isClaimed,
    "card-rewards--claimed": isClaimed
  });

  const handleClaim = () => showClaimModal(userBalance?.current.number, earningPercent.percent, undefined, true);

  return (
    <div className="claim-assigned-card">
      <div className="card-main">
        <div className="main-triangle" />

        <div className="card-header">
          <h2>{stakingName}</h2>
          <ClaimStakingIcon />
        </div>

        <div className="card-timeline">
          <StakingTimeline status={status} stages={stages} />
        </div>

        <div className="card-summary">
          <div className="staking-info">
            <CardInfoItem label={t("common:apr")} value={`${numberWithSpaces(earningPercent.apr)}%`} />
            <CardInfoItem label={t("common:duration")} value={stakingDuration} />
            <CardInfoItem
              label={t("common:endOfStaking")}
              value={formatDateTimeUTC(stages.earnStageTo, "DD MMM YYYY")}
            />
            <CardInfoItem
              label={t("common:yourStakingAmount")}
              value={`${numberWithSpaces(stakingAmount)} ${AppConfig.EthTokenSymbol}`}
            />
          </div>

          <div className="staking-actions">
            <Button variant="secondary" onClick={onModalOpen}>
              {t("common:details")}
            </Button>
            {!isClaimed && <Button onClick={handleClaim}>{t("common:claim")}</Button>}
          </div>
        </div>
      </div>

      <div className={rewardsClasses}>
        <div className="rewards-content">
          <RewardIcon />
          {isClaimed && <p className="rewards-text white-0 mt-6">{t("common:claimed")}!</p>}

          <p className="p1 white-0 mt-6">{t("common:yourEarned")}</p>
          <p className="rewards-text white-0">
            {numberWithSpaces(earnings.number)} {AppConfig.EthTokenSymbol}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ClaimAssignedCard;
