import React, { useCallback, useEffect, useState } from "react";
import { StakingBalanceStructOutput } from "staking-contract/lib/contracts/Staking";

import { AppConfig } from "@config/config";
import { IChildren } from "@src/types/IChildren.types";
import { FetchStatus } from "@src/types/api/FetchStatus.types";
import { StakingUserBalance } from "@src/types/Staking.types";
import { ETH_AGGREGATOR_CONTRACT } from "@services/eth/EthHooks";
import { parseNumericValue } from "@services/eth/utils/parseNumericValue";
import { deepEqual } from "@utils/equals/deepEqual";

import { useWallet } from "./WalletProvider";

interface BalancesData {
  status: FetchStatus;
  balances: StakingUserBalance[] | null;
  error: string | null;
}

interface ContextValue extends BalancesData {
  getStakingBalanceByIndex: (index: number) => StakingUserBalance | undefined;
  updateStakingBalances: (updatedBalances: StakingUserBalance[]) => void;
  updateStakingBalanceByIndex: (index: number, updatedBalance: StakingUserBalance) => void;
  fetchUserBalances: () => Promise<StakingBalanceStructOutput[] | undefined>;
  refetchUserBalances: () => Promise<StakingBalanceStructOutput[] | undefined>;
}

const parseUserBalance = (index: number, current: bigint, max: bigint): StakingUserBalance => {
  return {
    index,
    current: parseNumericValue(current),
    max: parseNumericValue(max)
  };
};

const initialContextData = (): BalancesData => ({
  status: "idle",
  balances: null,
  error: null
});

const UserBalancesContext = React.createContext(null as any);

export const UserBalancesProvider = ({ children }: IChildren) => {
  const [balancesData, setBalancesData] = useState<BalancesData>(initialContextData());
  const { metamaskStatus, walletAddress } = useWallet();

  const _getUserBalances = async (address: string) => await ETH_AGGREGATOR_CONTRACT.getUserBalances(address);

  useEffect(() => {
    fetchUserBalances();
  }, [metamaskStatus, walletAddress]);

  useEffect(() => {
    if (balancesData.status !== "success") return;
    const intervalId = setInterval(refetchUserBalances, AppConfig.UpdateBalanceInterval);

    return () => clearInterval(intervalId);
  }, [balancesData.balances]);

  const getStakingBalanceByIndex = (index: number) => {
    return balancesData.balances?.find((balance) => balance.index === index);
  };

  const updateStakingBalances = (updatedBalances: StakingUserBalance[]) => {
    setBalancesData((prevState) => ({
      ...prevState,
      balances: prevState.balances ? [...prevState.balances, ...updatedBalances] : updatedBalances
    }));
  };

  const updateStakingBalanceByIndex = (index: number, updatedBalance: StakingUserBalance) => {
    setBalancesData((prevState) => {
      if (!prevState.balances || index < 0 || index >= prevState.balances.length) {
        return prevState;
      }

      const updatedBalances = prevState.balances.map((item, i) => (i === index ? updatedBalance : item));

      return {
        ...prevState,
        balances: updatedBalances
      };
    });
  };

  const fetchUserBalances = useCallback(async () => {
    if (metamaskStatus !== "connected" || !walletAddress) return;
    setBalancesData((prevState) => ({ ...prevState, status: "loading" }));

    const res = await _getUserBalances(walletAddress);

    if (res) {
      const parsedUserBalances = res?.map(({ current, max }, index) => {
        return parseUserBalance(index, current, max);
      });

      setBalancesData((prevState) => ({ ...prevState, status: "success", balances: parsedUserBalances }));
    } else {
      setBalancesData((prevState) => ({ ...prevState, status: "failed", error: "Something went wrong!" }));
    }

    return res;
  }, [metamaskStatus, walletAddress]);

  const refetchUserBalances = async () => {
    if (metamaskStatus !== "connected" || !walletAddress || !balancesData.balances) return;

    const res = await _getUserBalances(walletAddress);

    if (res) {
      const parsedUserBalances = res?.map(({ current, max }, index) => {
        return parseUserBalance(index, current, max);
      });

      const isEqual = deepEqual(parsedUserBalances, balancesData.balances);
      if (isEqual) return;

      setBalancesData((prevState) => ({ ...prevState, status: "success", balances: parsedUserBalances }));
    } else {
      setBalancesData((prevState) => ({ ...prevState, status: "failed", error: "Something went wrong!" }));
    }

    return res;
  };

  const contextValue: ContextValue = {
    ...balancesData,
    getStakingBalanceByIndex,
    updateStakingBalances,
    updateStakingBalanceByIndex,
    fetchUserBalances,
    refetchUserBalances
  };

  return <UserBalancesContext.Provider value={contextValue}>{children}</UserBalancesContext.Provider>;
};

export const useUserBalances = (): ContextValue => {
  const context = React.useContext(UserBalancesContext);

  if (context === undefined) {
    throw new Error("useUserBalances must be used within an UserBalancesProvider");
  }

  return context;
};
