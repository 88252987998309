import React, { createContext, useContext, useState, ReactNode } from "react";

// Define the context value type
interface ModalContextValue {
  pushModal: (modal: ReactNode) => void;
  popModal: () => void;
  replaceModal: (modal: ReactNode) => void;
  queueLength: number;
}

// Create the context
const ModalContext = createContext<ModalContextValue | undefined>(undefined);

// Hook to use the modal context
export const useModalContext = (): ModalContextValue => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("useModal must be used within a ModalProvider");
  }
  return context;
};

// ModalProvider component to provide the modal context to its children
export const ModalProvider = ({ children }: { children: ReactNode }) => {
  const [modalQueue, setModalQueue] = useState<ReactNode[]>([]);

  const pushModal = (modal: ReactNode) => setModalQueue((prevQueue) => [...prevQueue, modal]);

  const popModal = () => setModalQueue((prevQueue) => prevQueue.slice(1));

  const replaceModal = (modal: ReactNode) => setModalQueue((prevQueue) => [modal, ...prevQueue.slice(1)]);

  return (
    <ModalContext.Provider value={{ pushModal, popModal, replaceModal, queueLength: modalQueue.length }}>
      {children}
      {modalQueue[0] ?? null}
    </ModalContext.Provider>
  );
};
