import React, { useEffect } from "react";

import { IChildren } from "@src/types/IChildren.types";
import { StorageKeys } from "@config/storageKeys";
import { BrowserStorageManager } from "@utils/browser/BrowserStorageManager";
import useBoolean from "@hooks/useBoolean";

import ArchivedStakingInfo from "./ArchivedStakingInfo/ArchivedStakingInfo";

const TIME_AFTER_WHICH_MODAL_DISPLAYED = 8 * 60 * 60 * 1000; // 8h

const isOlderThan = (timestamp: number, timeInMilliseconds: number): boolean => {
  const now = Date.now();
  return now - timestamp > timeInMilliseconds;
};

const ArchivedStaking = ({ children }: IChildren) => {
  const [isModalOpen, openModal, closeModal] = useBoolean(false);

  useEffect(() => {
    const showArchivedStakingTimestamp = BrowserStorageManager.readLocalStorage<number>(
      StorageKeys.ShowArchivedStakingTimestamp
    );

    if (showArchivedStakingTimestamp) {
      const shouldShowModal = isOlderThan(showArchivedStakingTimestamp, TIME_AFTER_WHICH_MODAL_DISPLAYED);

      if (shouldShowModal) {
        openModal();
        return;
      }

      return;
    } else {
      openModal();
    }
  }, []);

  const handleCloseModal = () => {
    const currentTimestamp = new Date().getTime();
    BrowserStorageManager.writeLocalStorage(StorageKeys.ShowArchivedStakingTimestamp, currentTimestamp);

    closeModal();
  };

  return (
    <>
      {children}

      {isModalOpen && <ArchivedStakingInfo onClose={handleCloseModal} />}
    </>
  );
};

export default ArchivedStaking;
