import React from "react";
import { useTranslation } from "react-i18next";

import { supportedBrowsers } from "@constants/supportedBrowsers";

import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/overlay/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import Group from "@components/arrangement/Group/Group";
import Stack from "@components/arrangement/Stack/Stack";

import "./UnsupportedBrowserModal.scss";

interface ModalProps {
  onClose?(): void;
}

const UnsupportedBrowserModal = ({ onClose }: ModalProps) => {
  const { t } = useTranslation(["modals"]);

  return (
    <Portal>
      <Backdrop open>
        <Modal onClose={onClose}>
          <div className="unsupported-browser-modal">
            <h3 className="bold text-center mb-8">{t("modals:unsupportedBrowser.title")}</h3>
            <p className="p0 text-center mb-3">{t("modals:unsupportedBrowser.listBrowser")}</p>

            <Group gap={32} justifyContent="center" fullWidth flexWrap="wrap" className="mt-4">
              {supportedBrowsers.map(({ browser, path, alt }, index) => (
                <Stack key={index} rowGap={16} alignItems="center">
                  <img src={path} alt={alt} width={50} height={50} />
                  <p className="p1">{browser}</p>
                </Stack>
              ))}
            </Group>
          </div>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default UnsupportedBrowserModal;
