import React from "react";
import classNames from "classnames";

import { AccordionTabProps, AccordionActiveState, AccordionTabContextValue } from "../../Accordion.types";
import "./AccordionTab.scss";

const AccordionTabContext = React.createContext(null as any);

const AccordionTab = ({ children, inititalVariant = "default" }: AccordionTabProps) => {
  const [variant, setVariant] = React.useState<AccordionActiveState>(inititalVariant);

  const turnDefault = () => setVariant("default");
  const turnHover = () => setVariant("hover");
  const turnActive = () => setVariant("active");

  const handleHover = () => {
    if (variant === "default") return turnHover();
    return;
  };

  const handleClick = () => {
    if (variant === "active") return turnHover();
    return turnActive();
  };

  const handleBlur = () => {
    if (variant === "hover") return turnDefault();
    return;
  };

  const contextValue: AccordionTabContextValue = {
    variant,
    handleHover,
    handleClick,
    handleBlur
  };

  const dividerClasses = classNames("accordion-tab-divider", `accordion-tab-divider-${variant}`);

  return (
    <AccordionTabContext.Provider value={contextValue}>
      <div className="accordion-tab">
        {children}

        <hr className={dividerClasses} />
      </div>
    </AccordionTabContext.Provider>
  );
};

export const useAccordionTab = (): AccordionTabContextValue => {
  const context = React.useContext(AccordionTabContext);

  if (context === undefined) {
    throw new Error("useAccordionTab must be used within an AccordionTab");
  }

  return context;
};

export default AccordionTab;
