import React, { useEffect } from "react";

import { IChildren } from "@src/types/IChildren.types";

import useBoolean from "@hooks/useBoolean";
import UnsupportedBrowserModal from "./UnsupportedBrowserModal/UnsupportedBrowserModal";

const SupportedBrowserGuard = ({ children }: IChildren) => {
  const [isModalOpen, openModal, closeModal] = useBoolean();

  const isSupportedBrowser = ["Firefox", "Chrome", "Brave", "Edge", "Opera", "OPR"].some((browser) =>
    navigator.userAgent.includes(browser)
  );

  const isUnsupportedBrowser = !isSupportedBrowser;

  useEffect(() => {
    if (isUnsupportedBrowser) {
      openModal();
    } else {
      closeModal();
    }
  }, [isUnsupportedBrowser]);

  return (
    <>
      {children}
      {isModalOpen && <UnsupportedBrowserModal onClose={closeModal} />}
    </>
  );
};

export default SupportedBrowserGuard;
