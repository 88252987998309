import { useTranslation } from "react-i18next";

import { useWallet } from "@contexts/WalletProvider";

import Group from "@components/arrangement/Group/Group";
import Checkbox from "@components/toolkit/Checkbox/Checkbox";
import Button from "@components/toolkit/Button/Button";
import ConnectMetamaskBtn from "@components/common/ConnectMetamaskBtn/ConnectMetamaskBtn";

import "./StakingModalInput.scss";

interface IStakingModalInput {
  stakeValue: string;
  tosLink: string;
  acceptedToS: boolean;
  onChangeToS: () => void;
  onChange: (value: string) => void;
  onStake: () => Promise<void>;
}

const StakingModalInput = ({
  stakeValue,
  acceptedToS,
  onChangeToS,
  onChange,
  onStake,
  tosLink
}: IStakingModalInput) => {
  const { t } = useTranslation(["modals"]);
  const { tokenBalance, metamaskStatus } = useWallet();

  const setPercentage = (percentage: number) => {
    if (!tokenBalance) return;
    const percentageValue = (percentage * Number(tokenBalance.bigint)) / 10 ** 8;
    onChange(percentageValue.toString());
  };

  return (
    <>
      <p className="p1 input-label">{t("modals:staking.stakingAmount")}</p>
      <div className="staking-modal-input">
        <Group className="input-wrapper">
          <input
            value={stakeValue}
            placeholder="0"
            className="input pr-3"
            onChange={(e) => onChange(e.currentTarget.value.replace(/[^0-9.]/g, ""))}
          />
          <h3>SKEY</h3>
        </Group>

        <Group className="percentage-buttons mt-4" alignItems="center" justifyContent="center" gap={8}>
          <div tabIndex={0} onClick={() => setPercentage(0.25)}>
            <p>25%</p>
          </div>
          <div tabIndex={0} onClick={() => setPercentage(0.5)}>
            <p>50%</p>
          </div>
          <div tabIndex={0} onClick={() => setPercentage(0.75)}>
            <p>75%</p>
          </div>
          <div tabIndex={0} onClick={() => setPercentage(1)}>
            <p>Max</p>
          </div>
        </Group>
      </div>

      <div className="stake-button-wrapper">
        <Checkbox
          onChange={onChangeToS}
          label={t("modals:staking.termsAndConditions")}
          required
          checked={acceptedToS}
          link={tosLink}
          linkLabel={t("modals:staking.staking")}
        />
        {metamaskStatus === "connected" && (
          <Button fullWidth disabled={!stakeValue || !Number(stakeValue) || !acceptedToS} onClick={onStake}>
            {t("modals:staking.confirm")}
          </Button>
        )}
        {metamaskStatus !== "connected" && <ConnectMetamaskBtn />}
      </div>
    </>
  );
};

export default StakingModalInput;
