import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { useWallet } from "@contexts/WalletProvider";

import Button from "@components/toolkit/Button/Button";
import Spinner from "@components/toolkit/Spinner/Spinner";

import "./ConnectMetamaskBtn.scss";

interface ConnectMetamaskBtnProps {
  style?: React.CSSProperties;
  className?: string;
}

const ConnectMetamaskBtn = ({ style, className }: ConnectMetamaskBtnProps) => {
  const { connectMetamask, metamaskStatus } = useWallet();
  const { t } = useTranslation("common");

  const connecting = metamaskStatus === "connecting";
  const connected = metamaskStatus === "connected";

  const classes = classNames("connect-metamask-btn", className);

  if (!connected) {
    return (
      <Button loading={connecting} onClick={connectMetamask} style={style} className={classes}>
        {!connecting && t("connectMetamask")}
        {connecting && <Spinner />}
      </Button>
    );
  }

  return <></>;
};

export default ConnectMetamaskBtn;
