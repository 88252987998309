import { useTranslation } from "react-i18next";

import { METAMASK_URL } from "@constants/constants";

import Backdrop from "@components/overlay/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import Portal from "@components/overlay/Portal/Portal";
import Group from "@components/arrangement/Group/Group";
import Button from "@components/toolkit/Button/Button";

import "./MetamaskInfo.scss";

interface MetamaskInfo {
  onClose: () => void;
}

const MetamaskInfo = ({ onClose }: MetamaskInfo) => {
  const { t } = useTranslation(["modals"]);

  const openMetamaskPage = () => {
    window.open(METAMASK_URL, "_blank");
    onClose();
  };

  return (
    <Portal>
      <Backdrop open>
        <Modal>
          <div className="metamask-modal-info">
            <img src="/images/icons/icon-metamask.svg" />

            <h2 className="title">{t("modals:noMetamask.title")}</h2>
            <p className="p1 description">{t("modals:noMetamask.description")}</p>

            <Group fullWidth gap={20}>
              <Button onClick={openMetamaskPage} fullWidth>
                {t("modals:noMetamask.goTo")}
              </Button>

              <Button onClick={onClose} variant="secondary" fullWidth>
                {t("modals:staking.close")}
              </Button>
            </Group>
          </div>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default MetamaskInfo;
