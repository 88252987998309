import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AddEthereumChainParameter, IMetaMaskContext } from "metamask-react/lib/metamask-context";
import { useMetaMask } from "metamask-react";
import { ethers } from "ethers";

import { AppConfig } from "@config/config";
import { IChildren } from "@src/types/IChildren.types";
import { TokenBalance } from "@src/types/Staking.types";
import { ETH_TOKEN_CONTRACT } from "@services/eth/EthHooks";
import { checkMetamaskAvailability } from "@services/eth/utils/checkMetamaskAvailability";
import useBoolean from "@hooks/useBoolean";
import { Web3Provider } from "@services/eth/EthProvider";

export type MetamaskStatus = IMetaMaskContext["status"];

interface ContextValue {
  metamaskAvailable: boolean;
  metamaskStatus: MetamaskStatus;
  walletAddress: string | null;
  chainId: string | null;
  isOnCorrectNetwork: boolean;
  tokenBalance: TokenBalance | null;
  isModalOpen: boolean;

  connectMetamask: () => Promise<string[] | null | void>;
  checkMetamaskAvailability: () => boolean;
  addChain: (parameters: AddEthereumChainParameter) => Promise<void>;
  switchNetwork: (chainId?: string) => Promise<void>;
  getTokenBalance: () => Promise<void>;
  openModal: () => void;
  closeModal: () => void;
}

const WalletContext = React.createContext(null as any);

export const WalletProvider = ({ children }: IChildren) => {
  const [metamaskAvailable, setMetamaskAvailable] = useState(false);
  const [tokenBalance, setTokenBalance] = useState<TokenBalance | null>(null);
  const [isModalOpen, openModal, closeModal] = useBoolean(false);

  const { status, account, chainId, connect, addChain, switchChain } = useMetaMask();

  const walletAddress = useMemo(() => account, [account]);
  const metamaskStatus = useMemo(() => status, [status]);
  const isOnCorrectNetwork = useMemo(() => AppConfig.EthNetworkConfig.chainId.num === Number(chainId), [chainId]);

  const switchNetwork = async (chainId?: string) => {
    const newChainId = chainId || AppConfig.EthNetworkConfig.chainId.hex;
    return await switchChain(newChainId);
  };

  const getTokenBalance = useCallback(async () => {
    if (!account || !isOnCorrectNetwork) return;

    try {
      const balance = await ETH_TOKEN_CONTRACT.balanceOf(account);
      if (tokenBalance?.bigint === balance) return;

      const currentBalance: TokenBalance = {
        bigint: balance,
        number: Number(ethers.formatUnits(balance, AppConfig.EthTokenDecimals)),
        decimals: AppConfig.EthTokenDecimals,
        symbol: AppConfig.EthTokenSymbol
      };

      setTokenBalance(currentBalance);
    } catch {
      console.error("couldn't get token balance");
    }
  }, [account, isOnCorrectNetwork, tokenBalance]);

  useEffect(() => {
    const available = checkMetamaskAvailability();
    setMetamaskAvailable(available);
  }, []);

  useEffect(() => {
    getTokenBalance();

    const interval = setInterval(getTokenBalance, AppConfig.UpdateBalanceInterval);
    return () => clearInterval(interval);
  }, [account, isOnCorrectNetwork]);

  const connectMetamashHandler = async () => {
    try {
      if (!Web3Provider) return openModal();
      return await connect();
    } catch (e) {
      console.log(e);
    }
  };

  const contextValue: ContextValue = {
    metamaskAvailable,
    metamaskStatus,
    walletAddress,
    chainId,
    isOnCorrectNetwork,
    tokenBalance,
    isModalOpen,

    connectMetamask: connectMetamashHandler,
    checkMetamaskAvailability,
    addChain,
    switchNetwork,
    getTokenBalance,
    openModal,
    closeModal
  };

  return <WalletContext.Provider value={contextValue}>{children}</WalletContext.Provider>;
};

export const useWallet = (): ContextValue => {
  const context = React.useContext(WalletContext);

  if (context === undefined) {
    throw new Error("useWallet must be used within an WalletContext");
  }

  return context;
};
