import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import useCountdownTimer from "./useCountdownTimer";

import "./CountdownTimer.scss";

interface CountdownTimerProps {
  targetDate: number; // timestamp
  variant?: "large" | "small";
  color?: "blue" | "green";
  className?: string;
}

const formatLeftTimeValue = (value: number) => value.toString().padStart(2, "0");

const CountdownTimer = ({ targetDate, variant = "large", color = "blue", className }: CountdownTimerProps) => {
  const { days, hours, minutes, seconds } = useCountdownTimer(new Date(targetDate));

  const { t } = useTranslation("common");

  const classes = classNames(`countdown-timer countdown-timer--${variant} countdown-timer--${color}`, className);

  return (
    <div className={classes}>
      <div className="countdown-timer-unit">
        <span className="timer-value">{days}</span>
        <span className="timer-label">{t("days")}</span>
      </div>

      <div className="countdown-timer-time-section">
        <div className="countdown-timer-unit countdown-timer-unit--time">
          <span className="timer-value">{formatLeftTimeValue(hours)}</span>
          <span className="timer-label">{t("hours")}</span>
        </div>

        <div className="countdown-timer-unit">
          <span className="timer-value">:</span>
        </div>

        <div className="countdown-timer-unit countdown-timer-unit--time">
          <span className="timer-value">{formatLeftTimeValue(minutes)}</span>
          <span className="timer-label">{t("minutes")}</span>
        </div>

        <div className="countdown-timer-unit">
          <span className="timer-value">:</span>
        </div>

        <div className="countdown-timer-unit countdown-timer-unit--time">
          <span className="timer-value">{formatLeftTimeValue(seconds)}</span>
          <span className="timer-label">{t("seconds")}</span>
        </div>
      </div>
    </div>
  );
};

export default CountdownTimer;
