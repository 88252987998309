/* eslint-disable react/no-unused-prop-types */
import Stack from "@components/arrangement/Stack/Stack";
import Lottie from "react-lottie-player";
import StakingSpinnerAnim from "@animations/staking-spinner-anim.json";
import Button from "@components/toolkit/Button/Button";
import WithdrawInfo from "../Withdraw/WithdrawInfo";
import { useTranslation } from "react-i18next";
import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/overlay/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import Dots from "@components/common/Dots/Dots";
import Group from "@components/arrangement/Group/Group";

interface IclaimValue {
  claimValue: string | number;
  onClose?: () => void;
  tryAgain?: () => void;
}

export const ClaimLoading = ({ claimValue }: IclaimValue) => {
  const { t } = useTranslation(["modals"]);

  return (
    <Portal>
      <Backdrop open>
        <Modal childrenClassName="text-wrapper">
          <Stack className="transaction-status" fullSize alignItems="center" justifyContent="center">
            <Lottie loop play speed={1.3} animationData={StakingSpinnerAnim} className="loading-animation" />
            <h2 className="transaction-status-title relative">
              {t("modals:staking.waitingForConfirmation")} <Dots />
            </h2>
            <WithdrawInfo userBalance={claimValue} label={t("modals:claim.amountLabel")} />
            <p className="p1 mt-7 w-60">
              {t("modals:staking.confirmThisTransaction")} <b>{t("modals:staking.metamaskWallet")}</b>
            </p>
          </Stack>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export const ClaimSuccess = ({ claimValue, onClose }: IclaimValue) => {
  const { t } = useTranslation(["modals"]);

  return (
    <Portal>
      <Backdrop open>
        <Modal>
          <Stack className="transaction-status" fullSize justifyContent="space-between">
            <Stack fullSize alignItems="center" justifyContent="center">
              <img src="/images/icons/icon-success-mark.svg" className="loading-animation" />
              <h2 className="transaction-status-title text-center">
                {t("modals:claim.success")}
                <br />
                {t("modals:claim.successCaption")}
              </h2>
              <WithdrawInfo userBalance={claimValue} label={t("modals:claim.amountLabel")} />
              <p className="p1 transaction-status-success-description">{t("modals:claim.stakeAgain")}</p>
            </Stack>
            <Button fullWidth onClick={onClose}>
              {t("modals:staking.close")}
            </Button>
          </Stack>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export const ClaimError = ({ claimValue, tryAgain, onClose }: IclaimValue) => {
  const { t } = useTranslation(["modals"]);

  return (
    <Portal>
      <Backdrop open>
        <Modal>
          <Stack className="transaction-status" fullSize justifyContent="space-between">
            <Stack fullSize alignItems="center" justifyContent="center">
              <img src="/images/icons/icon-error-mark.svg" className="loading-animation" />
              <h2 className="transaction-status-title text-center">{t("modals:staking.error")}</h2>
              <WithdrawInfo userBalance={claimValue} label={t("modals:claim.amountLabel")} />
              <p className="p1 transaction-status-error-description">{t("modals:staking.errorCaption")}</p>
            </Stack>

            <Group gap={20} fullWidth>
              <Button fullWidth onClick={tryAgain}>
                {t("modals:staking.tryAgain")}
              </Button>
              <Button fullWidth onClick={onClose} variant="secondary">
                {t("modals:staking.close")}
              </Button>
            </Group>
          </Stack>
        </Modal>
      </Backdrop>
    </Portal>
  );
};
