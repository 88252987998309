const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const MONTH = DAY * 30;

type Unit = "months" | "days" | "hours" | "minutes" | "seconds";

export const convertMiliseconds = (ms: number, unit: Unit = "months") => {
  switch (unit) {
    case "seconds":
      return Math.floor(ms / SECOND);
    case "minutes":
      return Math.floor(ms / MINUTE);
    case "hours":
      return Math.floor(ms / HOUR);
    case "days":
      return Math.floor(ms / DAY);
    case "months":
      return Math.floor(ms / MONTH);
    default:
      return Math.floor(ms / MONTH);
  }
};
