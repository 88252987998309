import React from "react";
import classNames from "classnames";
import { useCollapse } from "react-collapsed";

import { ReactComponent as ArrowUpIcon } from "@images/icons/icon-arrow-up.svg";
import Stack from "@components/arrangement/Stack/Stack";

import "./CollapsibleList.scss";

interface CollapsibleListProps {
  title: string;
  description?: string;
  defaultExpanded?: boolean;
  className?: string;
  headerClassName?: string;
  contentClassName?: string;
  children: React.ReactNode;
}

const CollapsibleList = ({
  title,
  description,
  defaultExpanded = false,
  className,
  headerClassName,
  contentClassName,
  children
}: CollapsibleListProps) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    easing: "cubic-bezier(0.69, -0.23, 0.31, 1.23)",
    duration: 500,
    defaultExpanded
  });

  const collapsibleListclasses = classNames("collapsible-list", className);
  const collapseBtnClasses = classNames("collapse-btn", { "collapse-btn--expanded": isExpanded });
  const headerClasses = classNames("collapsible-list-header", headerClassName);
  const contentClasses = classNames(contentClassName);

  return (
    <div className={collapsibleListclasses}>
      <div className={headerClasses}>
        <Stack rowGap={10}>
          <h2 className="title">{title}</h2>
          <p className="p1 description">{description}</p>
        </Stack>

        <button className={collapseBtnClasses} {...getToggleProps()}>
          <ArrowUpIcon />
        </button>
      </div>

      <div className="collapsible-list-content" {...getCollapseProps()}>
        <div className={contentClasses}>{children}</div>
      </div>
    </div>
  );
};

export default CollapsibleList;
