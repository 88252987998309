import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { BlockchainAddress } from "@src/types/Blockchain.types";
import { useStakingList } from "@contexts/StakingListContext/StakingList.context";
import { useUserBalances } from "@contexts/UserBalances.context";

import { ReactComponent as EmptyStakingListIcon } from "@images/icons/icon-empty-staking-list.svg";
import { ReactComponent as RefetchIcon } from "@images/icons/icon-refetch.svg";
import StakingListSkeleten from "@components/skeletons/StakingListSkeleten/StakingListSkeleten";
import CollapsibleList from "@components/common/CollapsibleList/CollapsibleList";
import StakingCard from "@components/common/StakingCard/StakingCard";
import StakingModal from "@components/modals/StakingModal/StakingModal";
import Button from "@components/toolkit/Button/Button";

import "./StakingList.scss";

const StakingList = () => {
  const [selectedStaking, setSelectedStaking] = useState<BlockchainAddress | string>("");
  const { stakingList, fetchStatus, fetchStakingList } = useStakingList();

  const { balances, getStakingBalanceByIndex } = useUserBalances();

  const { t } = useTranslation("staking");

  const showModal = (address: BlockchainAddress | string) => () => setSelectedStaking(address);
  const hideModal = () => setSelectedStaking("");

  const newAndIncomingStakings = useMemo(() => {
    if (!Array.isArray(stakingList)) return [];

    return stakingList?.filter((staking) => {
      const { index, status } = staking;

      if (status === "pending") return staking;
      if (status === "subscribe") {
        const userBalance = getStakingBalanceByIndex(index);
        const isAssigned = userBalance && userBalance.current.bigint > 0n;

        if (!isAssigned) return staking;
      }
    });
  }, [stakingList, balances]);

  const assignedStakings = useMemo(() => {
    if (!Array.isArray(stakingList)) return [];

    return stakingList?.filter((staking) => {
      const { index } = staking;

      const userBalance = getStakingBalanceByIndex(index);
      const isAssigned = userBalance && userBalance.max.bigint > 0n;

      if (isAssigned) return staking;
    });
  }, [stakingList, balances]);

  const otherStakings = useMemo(() => {
    if (!Array.isArray(stakingList)) return [];

    const newAndIncomingSet = new Set(newAndIncomingStakings);
    const assignedSet = new Set(assignedStakings);

    return stakingList?.filter((staking) => !newAndIncomingSet.has(staking) && !assignedSet.has(staking));
  }, [stakingList, newAndIncomingStakings, assignedStakings]);

  const noUpcomingStakings = stakingList && !newAndIncomingStakings;

  return (
    <div className="staking-list">
      {fetchStatus === "loading" && <StakingListSkeleten />}

      {fetchStatus === "error" && (
        <div className="no-upcoming-stakings">
          <RefetchIcon />
          <h1 className="error-title">{t("fetchError.title")}</h1>
          <Button onClick={() => fetchStakingList(true)}>{t("fetchError.tryAgain")}</Button>
        </div>
      )}

      {noUpcomingStakings && (
        <div className="no-upcoming-stakings">
          <EmptyStakingListIcon />
          <h1>{t("noUpcomingStakings.title")}</h1>
          <p className="p2">{t("noUpcomingStakings.description")}</p>
        </div>
      )}

      {newAndIncomingStakings && newAndIncomingStakings?.length > 0 && (
        <CollapsibleList
          title={t("stakingList.newAndIncoming.title")}
          description={t("stakingList.newAndIncoming.description")}
          headerClassName="list-header"
          contentClassName="list-content"
          defaultExpanded
        >
          {newAndIncomingStakings?.map((staking) => {
            return <StakingCard key={staking.address} staking={staking} onModalOpen={showModal(staking.address)} />;
          })}
        </CollapsibleList>
      )}

      {assignedStakings && assignedStakings?.length > 0 && (
        <CollapsibleList
          title={t("stakingList.myStakings.title")}
          description={t("stakingList.myStakings.description")}
          headerClassName="list-header"
          contentClassName="list-content"
          defaultExpanded
        >
          {assignedStakings?.map((staking) => {
            return <StakingCard key={staking.address} staking={staking} onModalOpen={showModal(staking.address)} />;
          })}
        </CollapsibleList>
      )}

      {otherStakings && otherStakings?.length > 0 && (
        <CollapsibleList
          title={t("stakingList.otherStakings.title")}
          description={t("stakingList.otherStakings.description")}
          headerClassName="list-header"
          contentClassName="list-content"
        >
          {otherStakings?.map((staking) => {
            return <StakingCard key={staking.address} staking={staking} onModalOpen={showModal(staking.address)} />;
          })}
        </CollapsibleList>
      )}

      {!!selectedStaking && (
        <StakingModal
          stakingInstance={stakingList!.find((staking) => staking.address === selectedStaking)!}
          onClose={hideModal}
        />
      )}
    </div>
  );
};

export default StakingList;
