import { StatkingData } from "@src/types/Staking.types";
import "./StakingModal.scss";
import { useState } from "react";
import { useApproveTx, useCurrentAllowance, useStakeTx } from "@services/eth/EthHooks";
import { useWallet } from "@contexts/WalletProvider";
import { ethers } from "ethers";
import "./StakingModal.scss";
import { useModalContext } from "@contexts/Modal.context";
import { StakingError, StakingLoading, StakingSuccess } from "./components/Stake/StakingStatusModal";
import StakingModalLayout from "./components/MainLayout/StakingModalLayout";

interface IStakingModal {
  onClose?: () => void;
  stakingInstance: StatkingData;
}

const StakingModal = ({ onClose, stakingInstance }: IStakingModal) => {
  const [acceptedToS, setAcceptedToS] = useState<boolean>(false);
  const [stakeValue, setStakeValue] = useState<string>("");
  const { tokenBalance, walletAddress } = useWallet();
  const { allowance } = useCurrentAllowance(walletAddress, stakingInstance.address);
  const { sendApproveTx } = useApproveTx(stakingInstance.address);

  const { sendStakeTx } = useStakeTx();
  const { pushModal, popModal, replaceModal, queueLength } = useModalContext();

  const toggleToS = () => setAcceptedToS(!acceptedToS);

  const onChange = (value: string) => {
    //TODO display error
    if (value.startsWith("0")) return;
    if (value.split(".")?.[1]?.length > 8) return; //cant have more than 8 digits
    if (Number(value) > stakingInstance.maxUserStake.number) {
      return setStakeValue(stakingInstance.maxUserStake.number.toString());
    }
    if (tokenBalance && Number(value) > tokenBalance?.number) return setStakeValue(tokenBalance?.number.toString());
    setStakeValue(value);
  };

  const handleClose = () => {
    popModal();
    onClose && onClose();
  };

  const handleStakeTx = async () => {
    try {
      pushModal(<StakingLoading stakeValue={stakeValue} />);
      if (!allowance || allowance < ethers.parseUnits(stakeValue, 8)) {
        await sendApproveTx(ethers.parseUnits(stakeValue, 8).toString());
      }
      await sendStakeTx(stakingInstance.index, ethers.parseUnits(stakeValue, 8));
      replaceModal(<StakingSuccess stakeValue={stakeValue} onClose={handleClose} />);
    } catch (e) {
      replaceModal(<StakingError stakeValue={stakeValue} tryAgain={popModal} onClose={handleClose} />);
    }
  };

  return (
    <>
      {!queueLength && (
        <StakingModalLayout
          onClose={handleClose}
          stakingInstance={stakingInstance}
          stakeValue={stakeValue}
          acceptedToS={acceptedToS}
          onChange={onChange}
          onChangeToS={toggleToS}
          onStake={handleStakeTx}
        />
      )}
    </>
  );
};

export default StakingModal;
