import React from "react";

import { IChildren } from "@src/types/IChildren.types";
import { DeviceInfo } from "@utils/browser/deviceInfo";

import UnavailableOnMobile from "./UnavailableOnMobile/UnavailableOnMobile";

const MobileDeviceGuard = ({ children }: IChildren) => {
  const isMobileDevice = DeviceInfo.isMobileDevice();

  return (
    <>
      {children}
      {isMobileDevice && <UnavailableOnMobile />}
    </>
  );
};

export default MobileDeviceGuard;
