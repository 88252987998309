import React, { useEffect } from "react";

import { IChildren } from "@src/types/IChildren.types";
import { useWallet } from "@contexts/WalletProvider";
import useBoolean from "@hooks/useBoolean";

import SwitchNetwork from "./SwitchNetwork/SwitchNetwork";

const SwitchNetworkGuard = ({ children }: IChildren) => {
  const [isModalOpen, openModal, closeModal] = useBoolean();
  const { metamaskStatus, isOnCorrectNetwork } = useWallet();

  const connected = metamaskStatus === "connected";
  const showSwitchNetworkModal = connected && !isOnCorrectNetwork;

  useEffect(() => {
    if (showSwitchNetworkModal) openModal();
    else closeModal();
  }, [metamaskStatus, isOnCorrectNetwork]);

  return (
    <>
      {children}
      {isModalOpen && <SwitchNetwork onClose={closeModal} />}
    </>
  );
};

export default SwitchNetworkGuard;
