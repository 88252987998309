import { useTranslation } from "react-i18next";

import { StatkingData } from "@src/types/Staking.types";
import { stakingNamesMap } from "@constants/stakingNamesMap";
import { stakingTosLinksMap } from "@constants/stakingTosLinksMap";
import { useUserBalances } from "@contexts/UserBalances.context";
import { useStakingModalFlow } from "../../useStakingModalFlow";
import { numberWithSpaces } from "@utils/numberWithSpaces";

import Backdrop from "@components/overlay/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import Portal from "@components/overlay/Portal/Portal";
import Link from "@components/toolkit/Link/Link";

import StakingModalClaim from "../Claim/StakingModalClaim";
import StakingModalDetails from "../Details/StakingModalDetails";
import StakingModalInput from "../Stake/StakingModalInput";
import StakingModalTimeline from "../Timeline/StakingModalTimeline";
import StakingModalWithdraw from "../Withdraw/StakingModalWithdraw";

interface IStakingModalLayout {
  onClose?: () => void;
  stakingInstance: StatkingData;
  stakeValue: string;
  acceptedToS: boolean;
  onChangeToS: () => void;
  onChange: (value: string) => void;
  onStake: () => Promise<void>;
}

const StakingModalLayout = ({
  onClose,
  stakingInstance,
  stakeValue,
  acceptedToS,
  onChange,
  onChangeToS,
  onStake
}: IStakingModalLayout) => {
  const { t } = useTranslation(["modals"]);

  const { getStakingBalanceByIndex } = useUserBalances();
  const userBalance = getStakingBalanceByIndex(stakingInstance.index)?.current.number;

  const { showWithdrawModal, showClaimModal } = useStakingModalFlow(stakingInstance.index);

  const handleWithdraw = () => showWithdrawModal(userBalance, onClose);
  const handleClaim = () => showClaimModal(userBalance, stakingInstance.earningPercent.percent, onClose);

  const stakingToSLink = stakingTosLinksMap.get(stakingInstance.address)!;
  return (
    <>
      <Portal>
        <Backdrop open>
          <Modal modalClassName="staking-modal" childrenClassName="wrapper" onClose={onClose}>
            <div className="date-details">
              <p className="p1 date-details-title">{t("modals:staking.details")}</p>

              <div className="divider" />

              <StakingModalTimeline stages={stakingInstance?.stages} />

              <div className="divider" />

              <p className="p0 mt-7 op-06 caption-text">
                {t("modals:staking.caption", { apr: numberWithSpaces(stakingInstance.earningPercent.apr) })}
              </p>
              <span className="p0 op-06 caption-text">{t("modals:staking.tos")} </span>
              <Link href={stakingToSLink} className="tos-link" target="_blank">
                {t("modals:staking.tosLink")}
              </Link>
            </div>

            <div className="details">
              <h2 className="title">{stakingNamesMap.get(stakingInstance?.address)}</h2>

              <div className="divider" />

              <StakingModalDetails
                duration={stakingInstance.duration.inDays}
                maxStakingAmount={stakingInstance.maxTotalStake}
                maxUserAmount={stakingInstance.maxUserStake}
                apy={stakingInstance.earningPercent}
              />

              {stakingInstance.status === "subscribe" && !userBalance && (
                <StakingModalInput
                  tosLink={stakingToSLink}
                  acceptedToS={acceptedToS}
                  onChangeToS={onChangeToS}
                  stakeValue={stakeValue}
                  onChange={onChange}
                  onStake={onStake}
                />
              )}

              {stakingInstance.status === "subscribe" && !!userBalance && (
                <StakingModalWithdraw userBalance={userBalance} onUnstake={handleWithdraw} />
              )}

              {stakingInstance.status === "earn" && !!userBalance && (
                <StakingModalClaim userBalance={userBalance} apy={stakingInstance.earningPercent.percent} />
              )}

              {stakingInstance.status === "claim" && !!userBalance && (
                <StakingModalClaim
                  userBalance={userBalance}
                  apy={stakingInstance.earningPercent.percent}
                  onClaim={handleClaim}
                />
              )}
            </div>
          </Modal>
        </Backdrop>
      </Portal>
    </>
  );
};

export default StakingModalLayout;
