interface ChainProps {
  name: string;
  chainId: {
    num: number;
    hex: string;
  };
  logoSrc: string;
}

export const ethNetworkConfig: Record<string, ChainProps> = {
  "1": {
    name: "Ethereum",
    chainId: {
      num: 1,
      hex: "0x1"
    },
    logoSrc: "/images/networks/ethereum.svg"
  },
  "11155111": {
    name: "Sepolia testnet",
    chainId: {
      num: 11155111,
      hex: "0x" + (11155111).toString(16)
    },
    logoSrc: "/images/networks/sepolia.png"
  }
};
