/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import classNames from "classnames";

import "./DaysProgressBar.scss";

interface DaysProgressBarProps {
  startDate: number;
  endDate: number;
  variant?: "subscribe" | "earn" | "claim";
  className?: string;
}

export const calculateProgress = (startDate: number, endDate: number): number => {
  if (startDate > endDate) {
    throw Error("The start date cannot be earlier than end date");
  }

  const now = Date.now();

  if (now < startDate) return 0;
  if (now > endDate) return 100;

  const totalDuration = endDate - startDate;
  const elapsedDuration = now - startDate;

  return (elapsedDuration / totalDuration) * 100;
};

export const daysRemaining = (endDate: number): number => {
  const now = Date.now();
  const remainingTime = endDate - now;

  if (remainingTime < 0) return 0;
  return Math.ceil(remainingTime / (1000 * 60 * 60 * 24));
};

const DaysProgressBar = ({ startDate, endDate, variant = "subscribe", className }: DaysProgressBarProps) => {
  const [progress, setProgress] = useState<number>(0);
  const [daysLeft, setDaysLeft] = useState<number>(0);

  useEffect(() => {
    setProgress(calculateProgress(startDate, endDate));
    setDaysLeft(daysRemaining(endDate));

    const interval = setInterval(() => {
      setProgress(calculateProgress(startDate, endDate));
      setDaysLeft(daysRemaining(endDate));
    }, 60000); // 60 [s]

    return () => clearInterval(interval);
  }, [startDate, endDate]);

  const progressBarClasses = classNames("days-progress-bar", className);
  const lineActiveStyle = { "--progress-width": `${progress}%` } as React.CSSProperties;
  const lineActiveClass = classNames(`line-active line-active-${variant}`, { "line-active-with-anim": progress < 100 });

  return (
    <div className={progressBarClasses}>
      <div style={lineActiveStyle} className={lineActiveClass} />
    </div>
  );
};

export default DaysProgressBar;
