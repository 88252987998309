import React from "react";
import classNames from "classnames";

import "./CardInfoItem.scss";

interface CardInfoItemProps {
  label: string;
  value: string;
  className?: string;
}

const CardInfoItem = ({ label, value, className }: CardInfoItemProps) => {
  const classes = classNames("card-info-item", className);

  return (
    <div className={classes}>
      <p className="p1">{label}</p>
      <p className="p2 bold">{value}</p>
    </div>
  );
};

export default CardInfoItem;
