import React from "react";
import { useTranslation } from "react-i18next";

import { AppConfig } from "@config/config";
import { stakingNamesMap } from "@constants/stakingNamesMap";
import { numberWithSpaces } from "@utils/numberWithSpaces";
import { formatDateTimeUTC } from "@utils/formatDateTime";

import { ReactComponent as NewStakingBadge } from "@images/layouts/badge-new-staking.svg";

import Button from "@components/toolkit/Button/Button";
import CountdownTimer from "@components/common/CountdownTimer/CountdownTimer";
import CardInfoItem from "@components/common/CardInfoItem/CardInfoItem";
import Group from "@components/arrangement/Group/Group";

import { StakingCardProps } from "../../StakingCard";
import "./PendingLaunchCard.scss";

const PendingLaunchCard = ({ staking, onModalOpen }: StakingCardProps) => {
  const { address, duration, stages, earningPercent, maxTotalStake } = staking;

  const { t } = useTranslation(["common"]);

  const stakingName = stakingNamesMap.get(address);
  const stakingDuration =
    duration.inMonths > 0 ? `${duration.inMonths} ${t("common:months")}` : `${duration.inDays} ${t("common:days")}`;

  return (
    <div className="pending-launch-card">
      <NewStakingBadge className="card-badge" />

      <div className="card-header">
        <h2>{stakingName}</h2>

        <Group gap={30}>
          <p className="timer-text">{t("common:prelaunchStartsIn")}</p>
          <CountdownTimer variant="small" color="green" targetDate={stages.subscribeStageFrom} />
        </Group>
      </div>

      <div className="card-main">
        <div className="staking-summary">
          <div className="staking-info">
            <CardInfoItem label={t("common:apr")} value={`${numberWithSpaces(earningPercent.apr)}%`} />
            <CardInfoItem
              label={t("common:start")}
              value={formatDateTimeUTC(stages.subscribeStageFrom, "DD MMM YYYY")}
            />
            <CardInfoItem label={t("common:duration")} value={stakingDuration} />
            <CardInfoItem
              label={t("common:pool")}
              value={`${numberWithSpaces(maxTotalStake.number)} ${AppConfig.EthTokenSymbol}`}
            />
          </div>

          <div className="staking-actions">
            <Button variant="secondary" onClick={onModalOpen}>
              {t("common:details")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PendingLaunchCard;
