import { ethers } from "ethers";
import { AppConfig } from "@config/config";

export const calculateEarnings = (userBalance: bigint, earningPercent: bigint) => {
  const earnings = (userBalance * earningPercent) / 10n ** 12n;

  return {
    bigint: earnings,
    number: Number(ethers.formatUnits(earnings, AppConfig.EthTokenDecimals))
  };
};
