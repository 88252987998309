import { useCallback, useState } from "react";

import { PromiseStatus } from "@src/types/PromiseStatus.types";

interface UseRequestOptions<T, Y extends any[]> {
  requestFn: (...args: Y) => Promise<T>;
  onSuccess?: (response: T) => void;
  onFail?: (error: Error) => void;
  onFinally?(): void;
}

interface UseRequestReturn<T, Y extends any[]> {
  response: T | null;
  error: Error | null;
  loading: boolean;
  status: PromiseStatus;
  request: (...args: Y) => Promise<T | undefined>;
  reset: () => void;
}

function useRequest<T, Y extends any[]>({
  requestFn,
  onSuccess,
  onFail,
  onFinally
}: UseRequestOptions<T, Y>): UseRequestReturn<T, Y> {
  const [response, setResponse] = useState<T | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<PromiseStatus>("idle");

  const request = useCallback(
    async (...args: Y) => {
      reset();
      setLoading(true);
      setStatus("pending");

      try {
        const res = await requestFn(...args);

        setResponse(res);
        setStatus("resolved");

        onSuccess?.(res);

        return res;
      } catch (e: unknown) {
        setStatus("rejected");

        if (!(e instanceof Error)) throw e;

        setError(e);

        onFail?.(e);
      } finally {
        setLoading(false);
        onFinally?.();
      }
    },
    [requestFn, onSuccess, onFail, onFinally]
  );

  const reset = useCallback(() => {
    setStatus("idle");
    setResponse(null);
    setError(null);
    setLoading(false);
  }, []);

  return { response, error, loading, status, request, reset };
}

export default useRequest;
