import React from "react";

import MainLayout from "@components/layouts/MainLayout/MainLayout";

import Hero from "./sections/Hero/Hero";
import StakingList from "./sections/StakingList/StakingList";
import Faq from "./sections/Faq/Faq";

import "./SkeyStakings.scss";

const SkeyStakings = () => {
  return (
    <div className="skey-stakings">
      <Hero />
      <StakingList />
      <Faq />
    </div>
  );
};

export default (
  <MainLayout>
    <SkeyStakings />
  </MainLayout>
);
