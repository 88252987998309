import React from "react";

import { AppConfig } from "@config/config";
import { TokenBalance } from "@src/types/Staking.types";
import { numberWithSpaces } from "@utils/numberWithSpaces";

import "./WalletBalance.scss";

interface WalletBalanceProps {
  balance: TokenBalance | null;
}

const WalletBalance = ({ balance }: WalletBalanceProps) => {
  const formattedBalance = balance ? numberWithSpaces(balance.number) : 0;
  const symbol = balance?.symbol || AppConfig.EthTokenSymbol;

  return (
    <p className="p1 wallet-balance">
      <span className="bold">{formattedBalance}</span> {symbol}
    </p>
  );
};

export default WalletBalance;
