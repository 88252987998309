import React from "react";
import classNames from "classnames";

import Navbar from "@components/layoutElements/Navbar/Navbar";
import Footer from "@components/layoutElements/Footer/Footer";

import "./MainLayout.scss";

interface LayoutProps {
  children: React.ReactNode;
  className?: string;
}

const MainLayout = ({ children, className }: LayoutProps) => {
  const classes = classNames("main-layout", className);

  return (
    <div className={classes}>
      <div className="navbar-part">
        <Navbar />
      </div>

      <main className="main-part">{children}</main>

      <div className="footer-part">
        <Footer />
      </div>
    </div>
  );
};

export default MainLayout;
