import { ethers } from "ethers";

import { AppConfig } from "@config/config";

export const Web3Provider = window.ethereum ? new ethers.BrowserProvider(window.ethereum) : null;

export const JsonRpcProvider = new ethers.JsonRpcProvider(AppConfig.EthRPCProviderUrl, undefined, {
  pollingInterval: 10000,
  polling: true,
  staticNetwork: true
});
