import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { PATHS } from "@router/paths";
import { scrollToTop } from "@utils/scrollUtils";
import { SKEY_NETWORK_PAGE } from "@constants/constants";
import { useWallet } from "@contexts/WalletProvider";

import Group from "@components/arrangement/Group/Group";
import WalletAddress from "@components/common/WalletAddress/WalletAddress";
import WalletBalance from "@components/common/WalletBalance/WalletBalance";

import { ReactComponent as SkeyStakingLogo } from "@images/skey-staking-logo.svg";
import { ReactComponent as SkeySygnet } from "@images/layouts/skey-sygnet.svg";

import "./Navbar.scss";

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { metamaskStatus, walletAddress, tokenBalance } = useWallet();

  const connected = metamaskStatus === "connected";

  const handleLogoClick = () => {
    if (location.pathname !== PATHS.main.path) {
      navigate(PATHS.main.path);
    } else {
      scrollToTop();
    }
  };

  return (
    <nav className="navbar">
      <div className="navbar-content">
        <button onClick={handleLogoClick}>
          <SkeyStakingLogo />
        </button>

        <Group colGap={40}>
          {connected && (
            <Group colGap={16}>
              <WalletBalance balance={tokenBalance} />
              <WalletAddress address={walletAddress || ""} />
            </Group>
          )}

          <a href={SKEY_NETWORK_PAGE} target="_blank" rel="noopener noreferrer nofollow" className="skey-network-link">
            <SkeySygnet />
            skey.network
          </a>
        </Group>
      </div>
    </nav>
  );
};

export default Navbar;
