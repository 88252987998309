import { SOCIAL_LINKS, TELEGRAM_CHANNELLS } from "./constants";

import { ReactComponent as TwitterIcon } from "@images/socialMedia/twitter.svg";
import { ReactComponent as YouTubeIcon } from "@images/socialMedia/youtube.svg";
import { ReactComponent as RedditIcon } from "@images/socialMedia/reddit.svg";
import { ReactComponent as FacebookIcon } from "@images/socialMedia/facebook.svg";
import { ReactComponent as DiscordIcon } from "@images/socialMedia/discord.svg";
import { ReactComponent as KakaoTalkIcon } from "@images/socialMedia/kakaotalk.svg";
import { ReactComponent as MediumIcon } from "@images/socialMedia/medium.svg";

export const socialLinks = [
  {
    url: SOCIAL_LINKS.TWITTER,
    icon: <TwitterIcon width="31" height="30" />
  },
  {
    url: SOCIAL_LINKS.YOUTUBE,
    icon: <YouTubeIcon width="31" height="30" />
  },
  {
    url: SOCIAL_LINKS.REDDIT,
    icon: <RedditIcon width="31" height="30" />
  },
  {
    url: SOCIAL_LINKS.FACEBOOK,
    icon: <FacebookIcon width="31" height="30" />
  },
  {
    url: SOCIAL_LINKS.DISCORD,
    icon: <DiscordIcon width="31" height="30" />
  },
  {
    url: SOCIAL_LINKS.KAKAO_TALK,
    icon: <KakaoTalkIcon width="31" height="30" />
  },
  {
    url: SOCIAL_LINKS.MEDIUM,
    icon: <MediumIcon width="31" height="30" className="medium-icon" />
  }
];

export const telegramChannels = [
  {
    label: "Skey Network News >",
    url: TELEGRAM_CHANNELLS.TELEGRAM_1
  },
  {
    label: "Skey Network Chat Official >",
    url: TELEGRAM_CHANNELLS.TELEGRAM_2
  },
  {
    label: "Skey Network Chat Korea >",
    url: TELEGRAM_CHANNELLS.TELEGRAM_3
  },
  {
    label: "Skey Network Chat PL >",
    url: TELEGRAM_CHANNELLS.TELEGRAM_4
  }
];
