import { useEffect, useState } from "react";

export interface LeftTime {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

// Convert to millisecond
const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

const getCurrentDateMilliseconds = () => {
  return new Date().getTime();
};

const getReturnTimeLeftValues = (timeLeft: number): LeftTime => {
  if (timeLeft <= 0) {
    return { days: 0, hours: 0, minutes: 0, seconds: 0 };
  }

  // calculate time left
  const days = Math.floor(timeLeft / DAY);
  const hours = Math.floor((timeLeft % DAY) / HOUR);
  const minutes = Math.floor((timeLeft % HOUR) / MINUTE);
  const seconds = Math.floor((timeLeft % MINUTE) / SECOND);

  return { days, hours, minutes, seconds };
};

const useCountdownTimer = (targetDate: Date) => {
  const targetDateMilliseconds = targetDate.getTime();

  const [timeLeft, setTimeLeft] = useState<number>(targetDateMilliseconds - getCurrentDateMilliseconds());

  useEffect(() => {
    const updateRemainingTime = () => {
      const currentDateMilliseconds = getCurrentDateMilliseconds();
      setTimeLeft(targetDateMilliseconds - currentDateMilliseconds);
    };

    updateRemainingTime();

    const interval = setInterval(() => {
      updateRemainingTime();
    }, SECOND);

    return () => clearInterval(interval);
  }, [targetDateMilliseconds]);

  return getReturnTimeLeftValues(timeLeft);
};

export default useCountdownTimer;
