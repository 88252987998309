import stakingEn from "@locales/en/staking.json";
import faqEn from "@locales/en/faq.json";
import modalsEn from "@locales/en/modals.json";
import layoutEn from "@locales/en/layout.json";
import apiResponseEn from "@locales/en/api-response.json";
import commonEn from "@locales/en/common.json";

export const resources = {
  en: {
    staking: stakingEn,
    faq: faqEn,
    modals: modalsEn,
    layout: layoutEn,
    apiResponse: apiResponseEn,
    common: commonEn
  }
};
