import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { StakingStages, StakingStatus } from "@src/types/Staking.types";
import { formatDateTimeUTC } from "@utils/formatDateTime";
import { getCurrentDateMiliseconds } from "@utils/getCurrentDate";

import { ReactComponent as InfoIcon } from "@images/icons/icon-info.svg";

import Group from "@components/arrangement/Group/Group";
import Tooltip from "@components/toolkit/Tooltip/Tooltip";
import DaysProgressBar from "@components/common/DaysProgressBar/DaysProgressBar";

import "./StakingTimeline.scss";

interface TimelineProps {
  status: StakingStatus;
  stages: StakingStages;
}

const StakingTimeline = ({ status, stages }: TimelineProps) => {
  const { subscribeStageFrom, subscribeStageTo, earnStageTo, claimStageTo } = stages;
  const currentDate = getCurrentDateMiliseconds();

  const { t } = useTranslation(["staking", "common"]);

  const formattedSubscribeStageTo = formatDateTimeUTC(stages.subscribeStageTo, "DD MMM YYYY");
  const formattedEarnStageTo = formatDateTimeUTC(stages.earnStageTo, "DD MMM YYYY");
  const formattedClaimStageTo = formatDateTimeUTC(stages.claimStageTo, "DD MMM YYYY");

  const isSubscribeStage = status === "subscribe";
  const isEarnStage = status === "earn";
  const isClaimStage = status === "claim";

  const isSubscribeStageEnded = subscribeStageTo <= currentDate;
  const isEarnStageEnded = earnStageTo <= currentDate;
  const isClaimStageEnded = claimStageTo <= currentDate;

  const subscribeStageTextClasses = classNames(`p1`, { bold: isSubscribeStage });
  const earnStageTextClasses = classNames(`p1`, { bold: isEarnStage });
  const claimStageTextClasses = classNames(`p1`, { bold: isClaimStage });

  return (
    <div className="staking-timeline">
      <div className="timeline-stage">
        <div className="stage-header">
          <p className={subscribeStageTextClasses}>1. Pre-launch</p>

          <Group colGap={6} alignItems="center">
            {isSubscribeStage && <p className={subscribeStageTextClasses}>{formattedSubscribeStageTo}</p>}
            {isSubscribeStageEnded && <p className="p1">{t("common:complete")}</p>}

            <Tooltip tip={t("staking:stagesInfo.prelaunch")} width={260} placement="top">
              <InfoIcon className="d-flex cursor-help" />
            </Tooltip>
          </Group>
        </div>

        <DaysProgressBar variant="subscribe" startDate={subscribeStageFrom} endDate={subscribeStageTo} />
      </div>

      <div className="timeline-stage">
        <div className="stage-header">
          <p className={earnStageTextClasses}>2. Staking</p>

          <Group colGap={6}>
            {isEarnStage && <p className={earnStageTextClasses}>{formattedEarnStageTo}</p>}
            {isEarnStageEnded && <p className="p1">{t("common:complete")}</p>}

            <Tooltip tip={t("staking:stagesInfo.staking")} width={300} placement="top">
              <InfoIcon className="d-flex cursor-help" />
            </Tooltip>
          </Group>
        </div>

        <DaysProgressBar variant="earn" startDate={subscribeStageTo} endDate={earnStageTo} />
      </div>

      <div className="timeline-stage">
        <div className="stage-header">
          <p className={claimStageTextClasses}>3. Claiming</p>

          <Group colGap={6}>
            {isClaimStage && <p className={claimStageTextClasses}>{formattedClaimStageTo}</p>}
            {isClaimStageEnded && <p className="p1">{t("common:complete")}</p>}

            <Tooltip tip={t("staking:stagesInfo.claiming")} width={300} placement="top">
              <InfoIcon className="d-flex cursor-help" />
            </Tooltip>
          </Group>
        </div>

        <DaysProgressBar variant="claim" startDate={earnStageTo} endDate={claimStageTo} />
      </div>
    </div>
  );
};

export default StakingTimeline;
