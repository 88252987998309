/* eslint-disable prefer-const */
export const deepEqual = (obj1: any, obj2: any, visited = new WeakMap()): boolean => {
  if (obj1 === obj2) return true;

  if (typeof obj1 !== "object" || obj1 === null || typeof obj2 !== "object" || obj2 === null) {
    return false;
  }

  if (visited.has(obj1) && visited.get(obj1) === obj2) {
    return true;
  }

  visited.set(obj1, obj2);

  if (obj1 instanceof Date && obj2 instanceof Date) {
    return obj1.getTime() === obj2.getTime();
  }

  if (obj1 instanceof Set && obj2 instanceof Set) {
    if (obj1.size !== obj2.size) return false;

    for (let item of obj1) {
      if (!obj2.has(item)) return false;
    }

    return true;
  }

  if (obj1 instanceof Map && obj2 instanceof Map) {
    if (obj1.size !== obj2.size) return false;

    for (let [key, value] of obj1) {
      if (!obj2.has(key) || !deepEqual(value, obj2.get(key), visited)) return false;
    }

    return true;
  }

  const keys1 = Reflect.ownKeys(obj1);
  const keys2 = Reflect.ownKeys(obj2);

  if (keys1.length !== keys2.length) return false;

  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key], visited)) {
      return false;
    }
  }

  return true;
};
