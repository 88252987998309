import React from "react";
import { useTranslation } from "react-i18next";

import { ARCHIVED_STAKING_URL } from "@constants/constants";

import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/overlay/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import Group from "@components/arrangement/Group/Group";
import Button from "@components/toolkit/Button/Button";

import { ReactComponent as InfoIcon } from "@images/icons/icon-info2.svg";

import "./ArchivedStakingInfo.scss";

interface ArchivedStakingInfoProps {
  onClose(): void;
}

const ArchivedStakingInfo = ({ onClose }: ArchivedStakingInfoProps) => {
  const { t } = useTranslation(["modals", "common"]);

  const handleOpenArchivedStaking = () => window.open(ARCHIVED_STAKING_URL, "_blank");

  return (
    <Portal>
      <Backdrop open>
        <Modal>
          <div className="archived-staking-info">
            <InfoIcon className="mb-7" />

            <h2 className="mb-1 title mb-4">{t("modals:archivedStaking.title")}</h2>
            <p className="p1 mb-8">{t("modals:archivedStaking.description")}</p>

            <Group colGap={20} fullWidth>
              <Button fullWidth onClick={onClose}>
                {t("common:close")}
              </Button>

              <Button variant="secondary" fullWidth onClick={handleOpenArchivedStaking}>
                {t("common:previousVersion")}
              </Button>
            </Group>
          </div>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default ArchivedStakingInfo;
