import React from "react";
import classNames from "classnames";

import "./StakingCardSkeleton.scss";

interface CardSkeletonProps {
  variant?: "base" | "withTimeline";
  className?: string;
}

const StakingCardSkeleton = ({ variant = "base", className }: CardSkeletonProps) => {
  const withTimeline = variant === "withTimeline";

  const classes = classNames(`staking-card-skeleton staking-card-skeleton--${variant}`, className);

  return (
    <div className={classes}>
      <div className="card-content">
        <div className="card-header">
          <div className="header-card-name" />
          <div className="header-counter" />
        </div>

        {withTimeline && (
          <div className="card-timeline">
            <div className="timeline-stage" />
            <div className="timeline-stage" />
            <div className="timeline-stage" />
          </div>
        )}

        <div className="card-summary">
          <div className="summary-info">
            <div className="info-section">
              <div className="info-header" />
              <div className="info-content" />
            </div>
            <div className="info-section">
              <div className="info-header" />
              <div className="info-content" />
            </div>
            <div className="info-section">
              <div className="info-header" />
              <div className="info-content" />
            </div>
            <div className="info-section">
              <div className="info-header" />
              <div className="info-content" />
            </div>
          </div>

          <div className="summary-actions" />
        </div>
      </div>
    </div>
  );
};

export default StakingCardSkeleton;
