import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import MainLayout from "@components/layouts/MainLayout/MainLayout";
import Button from "@components/toolkit/Button/Button";

import { ReactComponent as NotFoundIcon } from "@images/icons/icon-not-found.svg";

import "./NotFound.scss";

const NotFound = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("layout");

  const goBack = () => navigate(-1);

  return (
    <div className="not-found">
      <NotFoundIcon className="mb-6" />

      <h1 className="mb-1">{t("notFound.title")}</h1>
      <p className="p1 mb-5 not-found-description">{t("notFound.description")}</p>

      <Button onClick={goBack} style={{ minWidth: "200px" }}>
        {t("notFound.goBack")}
      </Button>
    </div>
  );
};

export default (
  <MainLayout>
    <NotFound />
  </MainLayout>
);
