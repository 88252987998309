import React from "react";

import StakingCardSkeleton from "../StakingCardSkeleton/StakingCardSkeleton";

import "./StakingListSkeleten.scss";

const StakingListSkeleten = () => {
  return (
    <div className="staking-list-skeleton">
      <div className="list-title" />
      <div className="list-description" />

      <StakingCardSkeleton variant="withTimeline" className="mt-7" />
      <StakingCardSkeleton className="mt-7" />
      <StakingCardSkeleton variant="withTimeline" className="mt-7" />
    </div>
  );
};

export default StakingListSkeleten;
