import { ethers } from "ethers";

export const parseEarningPercentToAPR = (subscribeStageTo: number, earnStageTo: number, percent: bigint) => {
  const stakingDurationMs = earnStageTo - subscribeStageTo;

  const daysInYear = 360;
  const yearInMs = daysInYear * 24 * 60 * 60 * 1000;
  const convertedPercent = Number(ethers.formatUnits(percent, 12));
  const apr = convertedPercent * (yearInMs / stakingDurationMs);

  return Math.round((apr * 100 + Number.EPSILON) * 100) / 100;
};
