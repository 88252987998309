import React from "react";
import classNames from "classnames";

import { BaseProps } from "@src/types/BaseProps.types";

import "./Checkbox.scss";
import Link from "@components/toolkit/Link/Link";

export interface CheckboxProps extends BaseProps<HTMLInputElement, "label"> {
  label?: string | React.ReactNode;
  value?: string;
  linkLabel?: string;
  link?: string;
  required?: boolean;
  disabled?: boolean;
  error?: string;
  className?: string;
}

const Checkbox = ({ label, value, link, linkLabel, required, disabled, error, className, ...props }: CheckboxProps) => {
  const checkboxClasses = classNames("checkbox", { "checkbox--disabled": disabled }, className);
  const checbkoxLabelClasses = classNames("checkbox-label", { required: required });

  return (
    <div className="checkbox-wrapper">
      <label htmlFor={props.id || props.name} className={checkboxClasses}>
        <input type="checkbox" value={value} disabled={disabled} className="checkbox-native" {...props} />

        <span className="checkbox-box" />

        {label && (
          <span className={checbkoxLabelClasses}>
            {label}
            {link && (
              <Link href={link} target="_blank">
                {linkLabel}
              </Link>
            )}
          </span>
        )}
      </label>

      {error && <p className="checkbox-error">{error}</p>}
    </div>
  );
};

export default Checkbox;
