import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { AppConfig } from "@config/config";
import { useWallet } from "@contexts/WalletProvider";
import useRequest from "@hooks/useRequest";

import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/overlay/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import Button from "@components/toolkit/Button/Button";
import Spinner from "@components/toolkit/Spinner/Spinner";

import "./SwitchNetwork.scss";

interface SwitchNetworkProps {
  onClose(): void;
}

const SwitchNetwork = ({ onClose }: SwitchNetworkProps) => {
  const [switchNetworkError, setSwitchNetworkError] = useState<string | null>(null);

  const { t } = useTranslation(["apiResponse", "modals", "common"]);

  const { switchNetwork } = useWallet();

  const { name: networkName, logoSrc: networkImage } = AppConfig.EthNetworkConfig;

  const { loading, request: handleSwitchNetwork } = useRequest({
    requestFn: async () => {
      setSwitchNetworkError(null);
      return switchNetwork();
    },
    onFail: (e) => {
      if ((e as any)?.code === 4001) {
        setSwitchNetworkError(t("apiResponse:error.userRejectedRequest"));
      } else {
        setSwitchNetworkError(t("apiResponse:error.userRejectedRequest"));
      }
    }
  });

  return (
    <Portal>
      <Backdrop open>
        <Modal onClose={onClose}>
          <div className="switch-network-modal">
            <img src={networkImage} alt={networkName} width={70} height="auto" className="mb-6" />

            <h3 className="bold mb-3">{t("modals:switchNetwork.title", { networkName })}</h3>
            <p className="p1 description mb-2">{t("modals:switchNetwork.description", { networkName })}</p>

            {switchNetworkError && <p className="p1 semi-bold danger mt-3">{switchNetworkError}</p>}

            <Button onClick={handleSwitchNetwork} loading={loading} fullWidth className="mt-5">
              {!loading && t("common:switchNetwork")}
              {loading && <Spinner />}
            </Button>
          </div>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default SwitchNetwork;
