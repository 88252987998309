import "./StakingModalWithdraw.scss";
import WithdrawInfo from "./WithdrawInfo";
import { useTranslation } from "react-i18next";
import Button from "@components/toolkit/Button/Button";

interface IStakingModalWithdraw {
  userBalance: string | number;
  onUnstake: () => Promise<void>;
}

const StakingModalWithdraw = ({ userBalance, onUnstake }: IStakingModalWithdraw) => {
  const { t } = useTranslation(["modals"]);

  return (
    <>
      <WithdrawInfo
        userBalance={userBalance}
        className="withdraw-info-margin"
        label={t("modals:staking.yourStakingAmount")}
      />
      <div className="button-wrapper">
        <Button variant="secondary" fullWidth onClick={onUnstake}>
          {t("modals:staking.withdraw")}
        </Button>
      </div>
    </>
  );
};

export default StakingModalWithdraw;
