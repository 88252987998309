import { useEffect } from "react";

import { Web3Provider } from "@services/eth/EthProvider";
import { IChildren } from "@src/types/IChildren.types";
import { useWallet } from "@contexts/WalletProvider";

import MetamaskInfo from "./MetamaskInfo/MetamaskInfo";

const MetamaskGuard = ({ children }: IChildren) => {
  const { isModalOpen, openModal, closeModal } = useWallet();

  useEffect(() => {
    if (!Web3Provider) openModal();
  }, []);

  return (
    <>
      {children}
      {isModalOpen && <MetamaskInfo onClose={closeModal} />}
    </>
  );
};

export default MetamaskGuard;
