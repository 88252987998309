import React from "react";
import classNames from "classnames";

import { BaseProps } from "@src/types/BaseProps.types";

import "./Button.scss";

export interface ButtonProps extends BaseProps<HTMLButtonElement> {
  type?: "button" | "submit" | "reset";
  variant?: "primary" | "secondary" | "trietary";
  children: React.ReactNode | React.ReactElement;
  loading?: boolean;
  uppercase?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
}

const Button = ({
  type = "button",
  variant = "primary",
  children,
  disabled = false,
  loading = false,
  uppercase = false,
  fullWidth = false,
  className = "",
  ...props
}: ButtonProps) => {
  const classes = classNames(
    `button button-${variant}`,
    {
      uppercase: uppercase,
      "full-width": fullWidth
    },
    className
  );

  return (
    <button type={type} disabled={disabled || loading} className={classes} {...props}>
      {children}
    </button>
  );
};

export default Button;
