import React, { useState } from "react";
import { usePopper } from "react-popper";

import { telegramChannels } from "@constants/socialLinks";
import useBoolean from "@hooks/useBoolean";

import { ReactComponent as TelegramIcon } from "@images/socialMedia/telegram.svg";
import ClickAwayListener from "@components/common/ClickAwayListener/ClickAwayListener";
import Link from "@components/toolkit/Link/Link";

import "./TelegramChannels.scss";

const TelegramChannels = () => {
  const [tooltipRef, setTooltipRef] = useState<HTMLElement | null>(null);
  const [popperRef, setPopperRef] = useState<HTMLElement | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isVisible, open, close, visibilityToggle] = useBoolean();

  const { styles, attributes } = usePopper(tooltipRef, popperRef, {
    placement: "auto",
    strategy: "absolute",
    modifiers: [
      {
        name: "offset",
        enabled: true,
        options: {
          offset: [0, -15]
        }
      },
      {
        name: "flip",
        options: {
          allowedAutoPlacements: ["right", "left", "bottom"],
          rootBoundary: "viewport",
          padding: 10
        }
      },
      {
        name: "preventOverflow",
        options: {
          rootBoundary: "viewport",
          padding: 10
        }
      }
    ]
  });

  return (
    <ClickAwayListener onClickAway={close}>
      <div className="telegram-channels">
        <button ref={setTooltipRef} role="button" onClick={visibilityToggle} className="dropdown-target">
          <TelegramIcon width="31" height="30" />
        </button>

        {isVisible && (
          <div
            ref={setPopperRef}
            role="dropdown"
            style={{ ...styles.popper, zIndex: 100 }}
            className="dropdown-menu"
            {...attributes.popper}
          >
            <p className="p1 white-5">Telegram</p>

            {telegramChannels.map(({ label, url }, index) => (
              <Link key={index} href={url} target="_blank">
                {label}
              </Link>
            ))}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default TelegramChannels;
