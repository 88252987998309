import Group from "@components/arrangement/Group/Group";
import WithdrawInfo from "../Withdraw/WithdrawInfo";

import "./StakingModalClaim.scss";
import Stack from "@components/arrangement/Stack/Stack";
import Button from "@components/toolkit/Button/Button";
import { useTranslation } from "react-i18next";
import { AppConfig } from "@config/config";

interface IStakingModalClaim {
  userBalance: number;
  apy: number;
  onClaim?: () => Promise<void>;
}

const StakingModalClaim = ({ userBalance, apy, onClaim }: IStakingModalClaim) => {
  const { t } = useTranslation(["modals"]);

  const claimAmount = (userBalance * apy) / 100;

  return (
    <>
      <WithdrawInfo className="border-top" userBalance={userBalance} label={t("modals:staking.yourStakingAmount")} />
      <Group className="claim-info" gap={20}>
        <img src="/images/icons/icon-claim.svg" />
        <Stack>
          <p className="p1">{onClaim ? t("modals:staking.yourEarnings") : t("modals:staking.youWillEarn")}</p>
          <p className="p1 bold claim-amount">
            {claimAmount} {AppConfig.EthTokenSymbol}
          </p>
        </Stack>
      </Group>

      {onClaim && (
        <div className="button-wrapper">
          <Button fullWidth onClick={onClaim}>
            {t("modals:staking.claim")}
          </Button>
        </div>
      )}
    </>
  );
};
export default StakingModalClaim;
